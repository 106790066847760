import { SVGProps } from ".";
const HistorySVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      {...p}
    >
      <g transform="translate(-98.66 -198)">
        <g transform="translate(-228.34 -749)">
          <rect
            width="48"
            height="48"
            transform="translate(327 947)"
            fill="none"
          />
        </g>
        <g transform="translate(-31.549 -433)">
          <path
            d="M6.385,23.949a13.288,13.288,0,0,0,5.83,5.924,12.965,12.965,0,0,0,8.148,1.239,13.1,13.1,0,0,0,7.278-3.932,13.589,13.589,0,0,0,2-15.8A13.259,13.259,0,0,0,23.583,5.7a12.956,12.956,0,0,0-8.191-.905,12.311,12.311,0,0,0-6.88,4.1A11.446,11.446,0,0,0,6.7,11.55m4.506.4L5.827,13.018,4.5,7.478m13.4,5.957v5.957l4.468,2.978"
            transform="translate(136.202 637.201)"
            fill="none"
            stroke={fill || "#9bc8e7"}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
          />
          <rect
            width="40"
            height="40"
            transform="translate(134.209 635)"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
};

export default HistorySVG;
