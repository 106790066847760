import { observer } from "mobx-react-lite";
import { CSSProperties, useState } from "react";
import styled from "styled-components";
import { setReferrer } from "../api/referral";
import { BOTTOM_TAB_ARRAY, TabData, TG_BOT_URL } from "../constants";
import { authStore } from "../store/auth-store";
import { store } from "../store/store";
import { ETabs } from "../types/types";
import { copyToClipboard, sleep } from "../utils";
import { MainBorderButtonV2, MainButton } from "./MainButton";
import { SCircularGradiantBox } from "./SCircularGradiantBox";
import { Flex, VFlex } from "./styled/Flex";
import AirdropSVG from "./SVG/AirdropSVG";
import CopySVG from "./SVG/CopySVG";
import FriendsSVG from "./SVG/FriendsSVG";
import { S12Body, SBody, SBody600 } from "./Typography";
interface BottomTabBarProps {
  style?: CSSProperties;
}

const SelectedBox = styled.div`
  background: radial-gradient(
    closest-side at 50% 50%,
    #00000010 0%,
    #5292bf10 100%
  );
  border: 1px solid #5292bf;
  border-radius: 8px;
  position: absolute;
  opacity: 1;
  left: 6px;
  right: 6px;
  height: 62px;
`;

const BottomTabBar = observer((props: BottomTabBarProps) => {
  const [isShowCopied, setIsShowCopied] = useState(false);
  const [isClickedMore, setIsClickedMore] = useState(false);
  return (
    <VFlex
      style={{
        // height: "100vh",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        height: isClickedMore ? "100vh" : "unset",
      }}
    >
      {isClickedMore && (
        <VFlex
          className="flex-1"
          style={{
            // backdropFilter: isClickedMore ? "blur(10px)" : "blur(0px)",
            background: "#FFFFFF10",
            zIndex: 10,
            justifyContent: "flex-end",
          }}
          onClick={() => {
            setIsClickedMore(false);
          }}
        >
          <VFlex
            style={{
              borderRadius: "8px 8px 0px 0px",
              width: "100%",
              height: 120,
              marginBottom: -5,
              background: "#192d3b",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
              position: "relative",
              gap: 8,
              padding: "12px 16px",
            }}
          >
            <MainBorderButtonV2
              className="self-stretch z-10"
              onClick={() => {
                setIsClickedMore(false);
                store.isOpenPage = "airdrop";
              }}
            >
              <AirdropSVG />
              <S12Body>Airdrop</S12Body>
            </MainBorderButtonV2>
            <MainBorderButtonV2
              className="self-stretch z-10"
              onClick={() => {
                setIsClickedMore(false);
                store.isOpenPage = "friend";
              }}
            >
              <FriendsSVG />
              <S12Body>Friends</S12Body>
            </MainBorderButtonV2>
            <SCircularGradiantBox
              style={{ width: "150%" }}
              centerColor="#000000A0"
              outerColor="#265e8390"
            />
          </VFlex>
        </VFlex>
      )}
      <VFlex
        style={{
          width: "100%",
          maxWidth: "100%",
          height:
            store.isOpenPage === "friend" || store.currentTab === ETabs.FRIENDS
              ? 148
              : 84,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          background: "#13181a",
          zIndex: 20,
          position: "relative",
        }}
      >
        {store.currentTab === ETabs.FRIENDS && (
          <Flex
            style={{
              // height: 84,
              alignItems: "center",
              padding: "0px 16px",
              gap: 10,
              flex: 1,
              position: "relative",
            }}
          >
            <MainButton
              style={{ height: 44, flex: 1 }}
              onClick={async () => {
                const url = `${TG_BOT_URL}?start=referral_${authStore.character?.referral_code ?? ""}`;
                if (navigator.share) {
                  navigator.share({
                    url,
                  });
                } else {
                  setIsShowCopied(true);
                  copyToClipboard(url || window.location.href);
                  setIsShowCopied(false);
                }
              }}
            >
              Invite Friends
            </MainButton>
            {!authStore.character?.referred_by && (
              <MainButton
                style={{ height: 44 }}
                onClick={async () => {
                  const code = await store.prompt({
                    title: "Input your referrer code",
                    content: "",
                    placeholder: "Your friend's referrer code...",
                  });
                  if (!code) return;
                  store.isShowLoadingDialog = true;
                  try {
                    store.isShowLoadingDialog = true;
                    const res = await setReferrer(code);
                    authStore.character = res.body.character;
                    store.claimedDialogState = {
                      title: "Referrer set!",
                      type: res.body.reward.type,
                      value: res.body.reward.amount,
                    };
                  } catch (error) {
                    store.showErrorDialog(error);
                  }
                  store.isShowLoadingDialog = false;
                }}
              >
                Referral code
              </MainButton>
            )}
            <MainBorderButtonV2
              style={{ height: 44, width: 64 }}
              onClick={async () => {
                setIsShowCopied(true);
                copyToClipboard(authStore.character?.referral_code ?? "");
                await sleep(1000);
                setIsShowCopied(false);
              }}
            >
              <CopySVG />
            </MainBorderButtonV2>
            <Flex
              style={{
                backgroundColor: "#000000",
                transform: "translateY(-60px)",
                pointerEvents: "none",
                transition: "all 0.3s",
                opacity: isShowCopied ? 1 : 0,
              }}
              className="absolute left-4 right-4 h-[38px] items-center justify-center rounded-lg"
            >
              <S12Body className="text-[#54DAF3]">
                Your referral code has been copied!
              </S12Body>
            </Flex>
          </Flex>
        )}
        <Flex style={{ flex: 1, minHeight: 84, height: 84 }}>
          <SCircularGradiantBox />
          {BOTTOM_TAB_ARRAY.map((tabKey) => {
            const { icon: Icon, title, isComingSoon } = TabData[tabKey];
            const selected = tabKey === store.currentTab;
            let color = "";
            if (isComingSoon) {
              color = "grey";
            } else if (isClickedMore || store.isOpenPage) {
              color = tabKey === ETabs.MORE ? "#72E4EC" : "white";
            } else {
              color = selected ? "#72E4EC" : "white";
            }
            return (
              <VFlex
                style={{
                  transition: "all 0.3s",
                  gap: 6,
                  alignItems: "center",
                  alignSelf: "stretch",
                  justifyContent: "center",
                  flex: 1,
                  position: "relative",
                }}
                key={tabKey}
                onClick={() => {
                  if (isComingSoon) {
                    return;
                  }
                  if (tabKey === ETabs.MORE) {
                    setIsClickedMore(true);
                    return;
                  }
                  setIsClickedMore(false);
                  store.isOpenPage = null;
                  store.currentTab = tabKey;
                }}
              >
                {selected && <SelectedBox />}
                <VFlex
                  style={{
                    width: 24,
                    height: 24,
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    style={{
                      position: "absolute",
                      transition: "all 0.3s",
                    }}
                    fill={color}
                  />
                </VFlex>
                {isComingSoon ? (
                  <VFlex className="items-center relative">
                    <SBody600 style={{ fontSize: 10, color }}>Coming</SBody600>
                    <SBody600
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        transform: "translateY(9px)",
                        color,
                      }}
                    >
                      Soon
                    </SBody600>
                  </VFlex>
                ) : (
                  <SBody
                    style={{
                      transition: "all 0.3s",
                      font: "normal normal 600 10px/13px Montserrat",
                      color,
                      fontWeight: selected ? "bold" : "normal",
                    }}
                  >
                    {title}
                  </SBody>
                )}
              </VFlex>
            );
          })}
        </Flex>
      </VFlex>
    </VFlex>
  );
});

export default BottomTabBar;
