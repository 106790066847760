import { InitDataUnsafe } from "@vkruglikov/react-telegram-web-app";
import { makeAutoObservable } from "mobx";
import { post, setAccessToken } from "../api/api";
import { getCharacter } from "../api/character";
import { IS_PROD } from "../constants";
import { Character } from "../types/api-types";
import { store } from "./store";

class AuthStore {
  character: Character | null = null;

  initDataUnsafe: InitDataUnsafe | undefined = undefined;

  get currentExp() {
    return this.expData?.displayExp ?? 0;
  }

  get nextExpRequired() {
    if (!this.character || !store.gameConfig || !this.expData) return 0;
    return (
      parseInt(
        store.gameConfig.character_table.level_up_exp_required[
          this.expData.level
        ] + "",
        10,
      ) ?? 0
    );
  }

  get expData() {
    if (!this.character || !store.gameConfig) return null;
    let displayExp = this.character.exp;
    let level = 0;
    while (true) {
      const v = parseInt(
        store.gameConfig.character_table.level_up_exp_required[level],
        10,
      );
      if (displayExp >= v) {
        level += 1;
        displayExp -= v;
      } else {
        break;
      }
    }
    // for (let i = 1; i <= this.character.level; i++) {
    //   const v = parseInt(
    //     store.gameConfig.character_table.level_up_exp_required[
    //       this.character.level
    //     ],
    //     10,
    //   );
    //   sum += v;
    //   if (i !== this.character.level) {
    //     displayExp -= v;
    //   }
    // }
    return {
      displayExp,
      level,
    };
  }

  get earnPerHour() {
    return this.character?.energy_per_hour ?? 0;
  }

  get isTestAcc() {
    return (
      this.character?.name === "O WO" ||
      this.character?.name?.trim?.() === "King" ||
      this.character?.name?.trim?.() === "Cherry M" ||
      this.initDataUnsafe?.user?.username === "someanothername" ||
      !IS_PROD
    );
  }

  jwt: string | null = null;
  loading: boolean = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async signInDev(tgUserId: string) {
    this.jwt = await (
      await post<{ jwt: string }>({
        uri: "/auth/backdoor_signin",
        body: { tg_user_id: tgUserId },
      })
    ).body.jwt;
    // "eyJhbGciOiJFUzM4NCJ9.eyJleHAiOjE3MzQyMDk2MDcuMCwiaXNzIjoid2VsbG5lc3MtY2xpY2tlciIsInRnX3VzZXJfaWQiOiJzdHJpbmcifQ.i637BerqBtIPvvDj8Txq4-z3MvpTCAXA-JsFxcouP5Ox1EWwHOTSwD4nWQacq-S7tHugJt_7SciIzN-OJaLsZYeRPGPOWFPBUrkMhkzbJLuGxg_BndDPn7KMuqKobcC7";
    setAccessToken(this.jwt);
    store.fetchGameConfig();
    const character = await getCharacter();
    this.character = character;
  }

  async signIn(initData: string) {
    const res = await await post<{ jwt: string; character: Character }>({
      uri: "/auth/signin_with_init_data",
      body: { init_data: initData },
    });
    this.jwt = res.body.jwt;
    setAccessToken(this.jwt);
    await store.fetchGameConfig();
    this.character = res.body.character;
  }

  signOut() {
    this.character = null;
    this.jwt = null;
    setAccessToken(""); // Clear token globally
  }

  get isAuthenticated() {
    return !!this.jwt;
  }

  get minWELLWithdraw() {
    return IS_PROD && !this.isTestAcc ? 150000 : 0;
  }

  get minTONWithdraw() {
    return IS_PROD && !this.isTestAcc ? 5 : 0;
  }
}

export const authStore = new AuthStore();
