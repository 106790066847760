import { SVGProps } from ".";
const SolidCircleQuestionMarkSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.289"
      height="18.849"
      viewBox="0 0 19.289 18.849"
      {...p}
    >
      <path
        id="Icon_fa-solid-circle-question"
        data-name="Icon fa-solid-circle-question"
        d="M9.645,18.849a9.536,9.536,0,0,0,9.645-9.424A9.536,9.536,0,0,0,9.645,0,9.536,9.536,0,0,0,0,9.424,9.536,9.536,0,0,0,9.645,18.849ZM6.4,6.085A2.111,2.111,0,0,1,8.386,4.712h2.2A2.35,2.35,0,0,1,12.96,7.035a2.321,2.321,0,0,1-1.194,2.017l-1.217.681A.9.9,0,0,1,8.74,9.719v-.5A.878.878,0,0,1,9.2,8.456l1.669-.935a.555.555,0,0,0,.286-.482.562.562,0,0,0-.569-.556h-2.2a.3.3,0,0,0-.283.2l-.015.044a.91.91,0,0,1-1.153.537.881.881,0,0,1-.55-1.126L6.4,6.089Zm2.042,6.873a1.206,1.206,0,1,1,1.206,1.178A1.192,1.192,0,0,1,8.439,12.958Z"
        fill={fill || "#84c9ff"}
      />
    </svg>
  );
};

export default SolidCircleQuestionMarkSVG;
