import styled from "@emotion/styled";
import { observer } from "mobx-react";
import { HtmlHTMLAttributes, useState } from "react";
import { COLORS } from "../constants";
import { Flex } from "./styled/Flex";
import SpinnerSVG, { SpinnerSVGProps } from "./SVG/SpinnerSVG";

export interface MainButtonProps extends HtmlHTMLAttributes<any> {
  onClick?: (evt: any) => void;
  disabled?: Boolean;
  isLoading?: Boolean;
  children: any;
  hoverClassName?: String;
  spinnerProps?: SpinnerSVGProps;
  Component?: any;
  large?: boolean;
  bgColorTop?: string;
  bgColorBottom?: string;
}

const StyledSButton = styled.button`
  background: linear-gradient(#221144, #351a6a);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fdb720;
  font-size: 18px;
  padding: 4px 12px;
`;

const StyledYellowButton = styled.button`
  background-color: #fdb720;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  font-size: 18px;
  padding: 4px 12px;
`;

const StyledYellowSButton = styled(StyledYellowButton)`
  padding: 0px 12px;
  height: 35px;
`;

const StyledYellowDIVButton = styled.div`
  white-space: nowrap;
  background-color: #fdb720;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  font-size: 18px;
  padding: 4px 12px;
`;

const StyledButtonV2 = styled.div`
  display: flex;
  white-space: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px 8px 14px;
  gap: 8px;
  height: ${(props: any) => (props.large ? "54px" : "40px")};
  background: ${(props: any) =>
    props.disabled
      ? "linear-gradient(180deg, #D6D6D6 0%, #9A9A9A 100%)"
      : `linear-gradient(180deg, ${props.bgColorTop || "#54daf3"} 0%, ${props.bgColorBottom || "#54a4f3"} 100%)`};
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: ${(props: any) => (props.large ? "18px" : "16px")};
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  white-space: nowrap !important;
`;

const StyledDiagonalGradiantButton = styled(StyledButtonV2)`
  background: ${(props: any) =>
    props.disabled
      ? "linear-gradient(-20deg, #d8d8d8 10%, #6c7072 50%, #d8d8d8 90%)"
      : "linear-gradient(-20deg, #5ea5d6 10%, #275473 50%, #5ea5d6 90%)"};
  color: white;
`;

const StyledBorderButtonV2 = styled(StyledButtonV2)`
  color: white;
  background: transparent;
  border: 1px solid
    ${(props) =>
      props.style?.color ? `${props.style?.color}50` : `${COLORS.primary2}`};
`;
// backdrop-filter: blur(20px);

export const MainButtonProvider = observer((props: MainButtonProps) => {
  const {
    isLoading,
    Component,
    style,
    onMouseEnter,
    onMouseLeave,
    disabled,
    spinnerProps,
    onClick,
    ...p
  } = props;
  const [isHovering, setIsHovering] = useState(false);
  let className =
    (isHovering && props.hoverClassName && props.hoverClassName) || "";
  if (disabled) {
    className = `${className} disabled`;
  }
  if (p.className) {
    className = `${className} ${p.className}`;
  }
  return (
    <Component
      onClick={async (evt) => {
        if (disabled || isLoading) return;
        onClick && onClick(evt);
      }}
      onMouseEnter={(evt) => {
        setIsHovering(true);
        onMouseEnter && onMouseEnter(evt);
      }}
      onMouseLeave={(evt) => {
        setIsHovering(false);
        onMouseLeave && onMouseLeave(evt);
      }}
      disabled={disabled}
      {...p}
      style={{
        cursor: disabled ? "unset" : "pointer",
        ...style,
      }}
      className={`simple-btn ${className}`}
      type="button"
    >
      {(() => {
        if (isLoading) {
          return (
            <div style={{ transform: "translateY(3px)" }}>
              <SpinnerSVG {...spinnerProps} />
            </div>
          );
        }
        return props.children;
      })()}
    </Component>
  );
});

export const MainButton = observer((props: MainButtonProps) => {
  return <MainButtonProvider Component={StyledButtonV2} {...props} />;
});

export const MainButtonV2 = observer((props: MainButtonProps) => {
  return <MainButtonProvider Component={StyledButtonV2} {...props} />;
});

export const DiagonalGradiantButton = observer((props: MainButtonProps) => {
  return (
    <MainButtonProvider Component={StyledDiagonalGradiantButton} {...props} />
  );
});

export const MainBorderButtonV2 = observer((props: MainButtonProps) => {
  return (
    <MainButtonProvider
      Component={StyledBorderButtonV2}
      spinnerProps={{
        style: {
          borderColor: COLORS.primary2,
        },
      }}
      {...props}
    />
  );
});

export const SmallButton = observer((props: MainButtonProps) => {
  return <MainButtonProvider Component={StyledSButton} {...props} />;
});

export const Button = observer((props: MainButtonProps) => {
  return (
    <MainButtonProvider
      Component={StyledYellowButton}
      spinnerProps={{ style: { borderColor: "black" } }}
      {...props}
    />
  );
});

export const SmallYellowButton = observer((props: MainButtonProps) => {
  return (
    <MainButtonProvider
      Component={StyledYellowSButton}
      spinnerProps={{}}
      {...props}
    />
  );
});

export const DivButton = observer((props: MainButtonProps) => {
  return (
    <MainButtonProvider
      className="simple-btn btn"
      Component={StyledYellowDIVButton}
      spinnerProps={{}}
      {...props}
    />
  );
});

type IconMainButtonProps = {
  onClick: (evt: any) => void;
  icon: string;
  text: String;
  iconStyle: any;
  disabled: Boolean;
};

export const IconMainButton = observer((props: IconMainButtonProps) => {
  return (
    <MainButton {...props}>
      <Flex style={{ alignItems: "center", marginRight: 6 }}>
        <img
          src={props.icon}
          alt=""
          style={{
            width: 24,
            height: "auto",
            marginRight: 20,
            ...props.iconStyle,
          }}
        />
        <span style={{ transform: "translateY(1px)" }}>{props.text}</span>
      </Flex>
    </MainButton>
  );
});

export const ShadowButton = styled(Flex)`
  background-color: ${COLORS.green2};
  box-shadow: 8px 8px 0px #292f33;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;

  /* dark-black */

  color: #050505;
  height: 54.4px;
  width: 197px;
  justify-content: center;
  margin-top: 80px;
`;
