import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";

import { store } from "../../store/store";
import { MainBorderButtonV2, MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import { S14Body, S20BodyBold } from "../Typography";
import BaseDialog from "./BaseDialog";

interface PromptDialogProps {
  style?: CSSProperties;
}

const ID = "PromptDialog";

const PromptDialog = observer((props: PromptDialogProps) => {
  return (
    <BaseDialog
      dialogID={ID}
      storeGetter={() => {
        return store.promptDialogState;
      }}
      onShow={() => {}}
      onClose={() => {
        store.promptDialogState = null;
      }}
      style={{
        gap: 8,
        transition: "all 0.3s ease",
        opacity: store.promptDialogState ? 1 : 0,
      }}
    >
      <VFlex
        style={{
          position: "relative",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <VFlex
          style={{
            border: "1px solid #376F9E",
            borderRadius: 16,
            overflow: "hidden",
            margin: "16px",
            // height: 300,
          }}
        >
          <Flex className="self-stretch items-center justify-center h-[58px] bg-[#0C1C27]">
            <S20BodyBold>{store.promptDialogState?.title}</S20BodyBold>
          </Flex>
          <VFlex
            className="self-stretch items-center justify-center flex-1"
            style={{
              background: "#1f262e",
            }}
          >
            {typeof store.promptDialogState?.content === "string" ? (
              <>
                <S14Body>{store.promptDialogState?.content}</S14Body>
                <input
                  className="m-4 p-2 text-white self-stretch"
                  style={{
                    borderBottom: "1px solid #81B3D6",
                    // textAlign: "center",
                  }}
                  onChange={(evt) => {
                    if (!store.promptDialogState) return;
                    store.promptDialogState.input = evt.target.value;
                  }}
                  placeholder={store.promptDialogState?.placeholder}
                  value={store.promptDialogState?.input}
                />
              </>
            ) : (
              store.promptDialogState?.content
            )}
          </VFlex>
        </VFlex>
        <MainButtonV2
          style={{
            margin: "0px 16px",
          }}
          onClick={async () => {
            store.promptDialogState?.resolve?.(store.promptDialogState?.input);
            store.promptDialogState?.onResult?.(store.promptDialogState?.input);
            store.promptDialogState = null;
          }}
        >
          {store.promptDialogState?.yesText || "Confirm"}
        </MainButtonV2>
        {!store.promptDialogState?.hideCancel && (
          <MainBorderButtonV2
            style={{
              margin: "16px 16px",
            }}
            onClick={() => {
              store.promptDialogState?.resolve?.();
              store.promptDialogState?.onResult?.();
              store.promptDialogState = null;
            }}
          >
            {store.promptDialogState?.yesText || "Cancel"}
          </MainBorderButtonV2>
        )}
      </VFlex>
    </BaseDialog>
  );
});

export default PromptDialog;
