import { LuckyPickAPIResBody, LuckyPickPools } from "../types/api-types";
import { ERewardTypes } from "../types/types";
import { get, post } from "./api";

export const playLuckyPick = async (currency: ERewardTypes) => {
  return post<LuckyPickAPIResBody>({
    uri: `/lucky_pick/play`,
    body: {
      currency,
    },
  });
  // return {
  //   body: {
  //     character: {
  //       tg_user_id: "1a",
  //       name: null,
  //       avatar_url: null,
  //       level: 8,
  //       energy: 1790546582,
  //       exp: 109524,
  //       ton_balance: "0.067",
  //       well_balance: "2444.9",
  //       items: [
  //         {
  //           id: "17bfd181-5d8b-470a-a38b-97690b118bb1",
  //           level: 8,
  //           item_id: "28",
  //           category: "2",
  //           rarity: "mythic",
  //           effects: ["exp_earn_per_hour"],
  //         },
  //         {
  //           id: "a06ba899-8973-4147-bad0-fdb7ac53a2aa",
  //           level: 5,
  //           item_id: "9",
  //           category: "0",
  //           rarity: "mythic",
  //           effects: ["exp_earn_per_hour"],
  //         },
  //         {
  //           id: "d93e82a5-7ae2-4bf6-8298-aae102a4470c",
  //           level: 4,
  //           item_id: "13",
  //           category: "1",
  //           rarity: "rare",
  //           effects: ["exp_earn_per_hour"],
  //         },
  //         {
  //           id: "5a6b788a-e108-4230-86d8-891bfc244455",
  //           level: 2,
  //           item_id: "38",
  //           category: "3",
  //           rarity: "rare",
  //           effects: ["exp_earn_per_hour"],
  //         },
  //         {
  //           id: "7e58b7ae-2e8a-4b80-b42e-53760a9a7beb",
  //           level: 10,
  //           item_id: "5",
  //           category: "0",
  //           rarity: "rare",
  //           effects: ["exp_earn_per_hour"],
  //         },
  //         {
  //           id: "7b65a9d3-d426-4942-93c9-ce5677fc65a7",
  //           level: 10,
  //           item_id: "6",
  //           category: "0",
  //           rarity: "rare",
  //           effects: ["exp_earn_per_hour"],
  //         },
  //         {
  //           id: "5a3d96ff-2e0f-486e-abfc-74db537b6add",
  //           level: 6,
  //           item_id: "25",
  //           category: "2",
  //           rarity: "rare",
  //           effects: ["exp_earn_per_hour"],
  //         },
  //         {
  //           id: "b803087c-29b9-43a9-a17f-eb26318aa457",
  //           level: 6,
  //           item_id: "11",
  //           category: "1",
  //           rarity: "basic",
  //           effects: ["exp_earn_per_hour"],
  //         },
  //         {
  //           id: "c2efe5cb-91ea-4411-9d59-c7fc9f72c605",
  //           level: 6,
  //           item_id: "12",
  //           category: "1",
  //           rarity: "basic",
  //           effects: ["exp_earn_per_hour"],
  //         },
  //         {
  //           id: "cdc4cc53-e481-45be-8b5a-aca47d52eb2f",
  //           level: 6,
  //           item_id: "33",
  //           category: "3",
  //           rarity: "basic",
  //           effects: ["exp_earn_per_hour"],
  //         },
  //         {
  //           id: "5f6ea64a-c531-40a5-ad9c-f25a392b4fa9",
  //           level: 6,
  //           item_id: "30",
  //           category: "3",
  //           rarity: "basic",
  //           effects: ["exp_earn_per_hour"],
  //         },
  //       ],
  //       last_sync: 1737854362,
  //       last_passive_reward_claim: 1736547856,
  //       referral_code: "nDF9CA",
  //       referred_by: null,
  //       referree: [],
  //       claimed_mission_rewards: [
  //         {
  //           mission_id: "mission-1",
  //           reward: {
  //             type: "energy",
  //             amount: 15000,
  //           },
  //           claimed_at: 1735034372,
  //         },
  //         {
  //           mission_id: "mission-2",
  //           reward: {
  //             type: "energy",
  //             amount: 15000,
  //           },
  //           claimed_at: 1735036269,
  //         },
  //       ],
  //       last_daily_reward_claimed_at: 1736535419,
  //       next_daily_reward_available_at: 1736553600,
  //       continuous_checkin_days: 1,
  //       ton_address: null,
  //       well_user_id: null,
  //       lucky_pick_play_log: {
  //         energy_play_times: 2,
  //         last_energy_reset_time: 1737854362,
  //       },
  //       created_at: 1736501283,
  //       energy_per_hour: 12714.0,
  //       lucky_pick_energy_cost: 40000.0,
  //       lucky_pick_ton_cost: 0.1,
  //       lucky_pick_well_cost: 1000.0,
  //       eggs: [
  //         {
  //           id: "3e5b3190-6b2c-4aac-b71a-e294f325ed19",
  //           rarity: "rare",
  //           unlock_at: 1736547856,
  //           is_standard: false,
  //         },
  //       ],
  //       standard_eggs: [
  //         {
  //           id: "faeb5007-8999-48ac-9e65-e59273afdd9d",
  //           rarity: "basic",
  //           unlock_at: 1736044309,
  //           is_standard: true,
  //         },
  //         {
  //           id: "a39105cc-c1bc-4594-a1c8-5ed43b36d877",
  //           rarity: "rare",
  //           unlock_at: 1736062312,
  //           is_standard: true,
  //         },
  //         {
  //           id: "65657ec0-bf78-4d91-9730-5d0697bc8716",
  //           rarity: "mythic",
  //           unlock_at: 1736119914,
  //           is_standard: true,
  //         },
  //       ],
  //     },
  //     reward: {
  //       id: "356a3b60-0e6c-4279-88e6-b4f81e8a6211",
  //       character_id: "1a",
  //       currency: ERewardTypes.TON,
  //       amount: "0.001",
  //       claimed_at: 1737854362,
  //       is_jackpot: true,
  //       multiplier: null,
  //       play_currency: "energy",
  //     },
  //   },
  // } as unknown as { body: LuckyPickAPIResBody };
};

export const listLuckyPickPools = async () => {
  return get<LuckyPickPools>({
    uri: `/lucky_pick/pools`,
  });
};

export const listLuckyPickRewardsHistory = async (
  currency: ERewardTypes,
  skip = 0,
  limit = 100,
) => {
  return get<{
    rewards: [
      {
        amount: 9007199254740991;
        character_id: "string";
        claimed_at: 9007199254740991;
        currency: "well";
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6";
        is_jackpot: true;
      },
    ];
  }>({
    uri: `/lucky_pick/rewards?skip=${skip}&limit=${limit}`,
    body: {
      currency,
    },
  });
};
