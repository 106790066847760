import { useTonWallet } from "@tonconnect/ui-react";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { address } from "ton-core";
import { levelUp, submitDepositTXHash } from "../api/character";
import { useTonClient } from "../hooks/ton-client";
import { authStore } from "../store/auth-store";
import { store } from "../store/store";
interface LoaderProps {}

const Loader = observer((props: LoaderProps) => {
  useEffect(() => {
    return autorun(async () => {
      if (authStore.character && store.gameConfig && authStore.expData) {
        if (authStore.expData.level > authStore.character.level) {
          authStore.character = await levelUp();
        }
      }
    });
  }, []);
  const wallet = useTonWallet();
  const tonClient = useTonClient();
  const [initDataUnsafe, initData] = useInitData();
  useEffect(() => {
    authStore.initDataUnsafe = initDataUnsafe;
  }, [initDataUnsafe]);
  useEffect(() => {
    if (wallet && tonClient) {
      (async () => {
        const newTxs = await tonClient.getTransactions(
          address(wallet?.account.address ?? ""),
          {
            limit: 10,
          },
        );
        const map = JSON.parse(
          localStorage.getItem("checkedTxs") || "{}",
        ) as Record<string, boolean>;
        for (const tx of newTxs) {
          const hash = tx.hash().toString("hex");
          if (map[hash]) {
            return;
          }
          const messages = tx.outMessages.values();
          for (let i = 0; i < messages.length; i++) {
            const message = messages[i];
            try {
              const originalBody = message.body.beginParse();
              let body = originalBody.clone();
              const uint = body.loadUint(32);
              if (uint === 0x0f8a7ea5 || uint === 4) {
                const res = await submitDepositTXHash(hash);
                authStore.character = res.body.character;
              }
            } catch (error) {}
          }
          map[hash] = true;
        }
        localStorage.setItem("checkedTxs", JSON.stringify(map));
      })();
    }
  }, [wallet, tonClient]);
  return null;
});

export default Loader;
