import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";

import { REWARD_DATA } from "../../constants";
import { store } from "../../store/store";
import { MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import { S14Body, S20Body, S20BodyBold, SBody } from "../Typography";
import BaseDialog from "./BaseDialog";

import _ from "lodash";
import { ERewardTypes } from "../../types/types";
import { numberInGroups } from "../../utils";
import ClaimedLottie from "../ClaimedLottie";

interface ClaimedDialogProps {
  style?: CSSProperties;
}

const ID = "ClaimedDialog";

const ClaimedDialog = observer((props: ClaimedDialogProps) => {
  return (
    <BaseDialog
      dialogID={ID}
      storeGetter={() => {
        return store.claimedDialogState;
      }}
      onShow={() => {}}
      onClose={() => {
        store.claimedDialogState = null;
      }}
      style={{
        gap: 8,
        transition: "all 0.3s ease",
        opacity: store.claimedDialogState ? 1 : 0,
      }}
    >
      <VFlex
        style={{
          position: "relative",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <VFlex
          style={{
            border: "1px solid #376F9E",
            borderRadius: 16,
            overflow: "hidden",
            margin: "16px",
            height: 229,
          }}
        >
          <Flex className="self-stretch items-center justify-center h-[58px] bg-[#0C1C27]">
            <S20BodyBold>
              {store.claimedDialogState?.title || "Claimed!"}
            </S20BodyBold>
          </Flex>
          {(() => {
            if (
              store.claimedDialogState?.rewards &&
              store.claimedDialogState?.rewards.length > 1
            ) {
              return (
                <VFlex className="flex-1 gap-2 self-stretch">
                  {store.claimedDialogState?.rewards.map((r) => {
                    if (r.type === "item") {
                      return (
                        <Flex className="gap-2">
                          <img
                            style={{ height: 49, width: 49 }}
                            src="/img/orb.png"
                          />
                          <VFlex>
                            <S14Body>
                              {numberInGroups(
                                store.gameConfig?.item_templates[r.item_id || 0]
                                  ?.item_name,
                                3,
                              )}
                            </S14Body>
                            <SBody>{_.upperFirst(r.rarity)}</SBody>
                          </VFlex>
                        </Flex>
                      );
                    }
                    return (
                      <Flex className="gap-2">
                        <img
                          style={{ height: 49, width: 49 }}
                          src={REWARD_DATA[r.type].icon}
                        />
                        <SBody>{numberInGroups(r.amount, 3)}</SBody>
                      </Flex>
                    );
                  })}
                </VFlex>
              );
            }
            const item = store.claimedDialogState?.rewards?.[0];
            if (item?.type === "item") {
              return (
                <VFlex
                  className="self-stretch items-center justify-center flex-1"
                  style={{
                    background: "#212932",
                  }}
                >
                  <img style={{ height: 69, width: 69 }} src="/img/orb.png" />
                  <Flex>
                    <S20Body>
                      &nbsp;
                      {
                        store.gameConfig?.item_templates[item.item_id || 0]
                          ?.item_name
                      }
                    </S20Body>
                  </Flex>
                  <SBody>{_.upperFirst(item.rarity)}</SBody>
                </VFlex>
              );
            }
            let type: string =
              item?.type ??
              store.claimedDialogState?.type ??
              ERewardTypes.Energy;
            if (type === "exp") type = "energy";
            const value = item?.amount ?? store.claimedDialogState?.value ?? 0;
            return (
              <VFlex
                className="self-stretch items-center justify-center flex-1"
                style={{
                  background: "#212932",
                }}
              >
                <img
                  style={{ height: 69, width: 69 }}
                  src={REWARD_DATA[type].icon}
                />
                <Flex>
                  <S20BodyBold>{`+${numberInGroups(value, 3)}`}</S20BodyBold>
                  <S20Body>
                    &nbsp;
                    {REWARD_DATA[
                      type || ERewardTypes.Energy
                    ].title.toUpperCase()}
                  </S20Body>
                </Flex>
              </VFlex>
            );
          })()}
        </VFlex>
        <MainButtonV2
          style={{
            margin: "0px 16px",
          }}
          onClick={() => {
            store.claimedDialogState = null;
          }}
          large
        >
          Done
        </MainButtonV2>
        {store.claimedDialogState && (
          <ClaimedLottie key={JSON.stringify(store.claimedDialogState)} />
        )}
      </VFlex>
    </BaseDialog>
  );
});

export default ClaimedDialog;
