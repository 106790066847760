import {
  useTonConnectModal,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import styled from "styled-components";
import { address } from "ton-core";
import { submitDepositTXHash } from "../../api/character";
import { IS_PROD, REWARD_DATA } from "../../constants";
import { useTonClient } from "../../hooks/ton-client";
import { authStore } from "../../store/auth-store";
import { store } from "../../store/store";
import { ERewardTypes } from "../../types/types";
import {
  copyToClipboard,
  getAddressString,
  numberInGroups,
  shortenNumberString,
} from "../../utils";
import Header from "../Header";
import { MainBorderButtonV2, MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import AirdropSVG from "../SVG/AirdropSVG";
import CopySVG from "../SVG/CopySVG";
import RegularCircleCheckSVG from "../SVG/RegularCircleCheckSVG";
import WalletSVG from "../SVG/WalletSVG";
import { S11Italic, S18BodyBold, SBody, SBodyBold } from "../Typography";
interface WithdrawTabPageProps {
  style?: CSSProperties;
}

const SWithdrawItemContainer = styled(Flex)`
  background: #00000010;
  border: 1px solid #82b3d6;
  border-radius: 9px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  height: 93px;
  padding: 0px 16px;
  align-items: center;
  gap: 14px;
`;

const WithdrawTabPage = observer((props: WithdrawTabPageProps) => {
  const { state, open, close } = useTonConnectModal();
  const wallet = useTonWallet();

  const [tonConnectUI, setOptions] = useTonConnectUI();

  const [initDataUnsafe, initData] = useInitData();
  const tonClient = useTonClient();
  return (
    <VFlex
      style={{
        flex: 1,
        alignItems: "stretch",
        overflow: "hidden",
      }}
    >
      <Header />
      <Flex
        style={{
          padding: "20px 16px 14px 16px",
          background: "linear-gradient(0deg,#122532 0%, #15354c 100%)",
          borderBottom: "1px solid #81B3D6",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AirdropSVG />
        <S18BodyBold>Withdraw</S18BodyBold>
      </Flex>
      <VFlex className="relative flex-1">
        <img
          src="/img/airdrop-illustration.png"
          style={{ height: "100%", alignSelf: "center", position: "absolute" }}
        />
      </VFlex>
      <VFlex
        style={{
          alignItems: "stretch",
          alignSelf: "stretch",
          padding: "8px 16px",
          marginBottom: "16px",
          gap: 8,
        }}
      >
        {[
          {
            type: ERewardTypes.WELL,
            value: authStore.character?.well_balance ?? 0,
            min: authStore.minWELLWithdraw,
          },
          {
            type: ERewardTypes.TON,
            value: authStore.character?.ton_balance ?? 0,
            min: authStore.minTONWithdraw,
          },
        ].map(({ type, value, min }) => {
          const rewardData = REWARD_DATA[type];
          return (
            <SWithdrawItemContainer style={{}}>
              <img
                src={rewardData.icon}
                style={{
                  width: 46,
                  height: 46,
                }}
              />
              <VFlex className="flex-1">
                <SBodyBold>{`$${rewardData.title}`}</SBodyBold>
                <SBodyBold style={{ color: rewardData.themeColor }}>
                  {numberInGroups(value, 10)}
                </SBodyBold>
              </VFlex>
              <VFlex
                style={{
                  width: 160,
                  gap: 8,
                }}
              >
                <MainButtonV2
                  // large
                  onClick={() => {
                    store.openWithdrawDialog(type as ERewardTypes.WELL);
                  }}
                  disabled={Number(value) < min}
                >
                  Withdraw
                </MainButtonV2>
                <S11Italic className="text-center text-[#A5A5A5]">
                  {`Minimum amount ${shortenNumberString(min)} $${rewardData.title.toUpperCase()}`}
                </S11Italic>
              </VFlex>
            </SWithdrawItemContainer>
          );
        })}
        {(authStore.isTestAcc || !IS_PROD) && (
          <MainButtonV2
            onClick={async () => {
              // const checker = async () => {
              //   if (!tonClient) return;
              //   const newTxs = await tonClient.getTransactions(
              //     address(wallet?.account.address ?? ""),
              //     {
              //       limit: 4,
              //     },
              //   );
              //   for (const tx of newTxs) {
              //     const hash = tx.hash().toString("hex");
              //     const messages = tx.outMessages.values();
              //     for (let i = 0; i < messages.length; i++) {
              //       const message = messages[i];
              //       try {
              //         const originalBody = message.body.beginParse();
              //         let body = originalBody.clone();
              //         const uint = body.loadUint(32);
              //         if (uint === 0x0f8a7ea5 || uint === 4) {
              //           const res = await submitDepositTXHash(hash);
              //           authStore.character = res.body.character;
              //         }
              //       } catch (error) {}
              //     }
              //   }
              // };
              // const res = await depositTON(0.01, wallet?.account.address ?? "");
              // const total =
              //   BigInt(Math.round(0.01 * Number(TON))) +
              //   BigInt(GAS_FEE.depositTON);
              // await tonConnectUI.sendTransaction({
              //   validUntil: (Date.now() + 5 * M_MS) / 1000,
              //   messages: [
              //     {
              //       address: CONTRACT_ADDR,
              //       amount: total.toString(),
              //       payload: res.body.payload,
              //     },
              //   ],
              // });
              // store.isShowLoadingDialog = false;
              // store.isShowTxInProgressDialog = true;
              // await tryForever(async () => {
              //   if (store.confirmDialogState) {
              //     return;
              //   }
              //   await checker();
              //   if (
              //     parseFloat(authStore.character?.ton_balance ?? "0") >=
              //     (authStore.character?.lucky_pick_ton_cost ?? 0)
              //   ) {
              //     store.isShowTxInProgressDialog = false;
              //     store.isShowLoadingDialog = false;
              //     await store.confirm({
              //       title: "Success",
              //       content: "Your transaction has been submitted successfully",
              //     });
              //     return;
              //   } else {
              //     throw new Error("not updated");
              //   }
              // }, "deposit");
              if (!tonClient) return;
              const newTxs = await tonClient.getTransactions(
                address(wallet?.account.address ?? ""),
                {
                  limit: 10,
                },
              );
              const map = JSON.parse(
                localStorage.getItem("checkedTxs") || "{}",
              ) as Record<string, boolean>;
              for (const tx of newTxs) {
                const hash = tx.hash().toString("hex");
                // if (map[hash]) {
                //   return;
                // }
                const messages = tx.outMessages.values();
                for (let i = 0; i < messages.length; i++) {
                  const message = messages[i];
                  try {
                    const originalBody = message.body.beginParse();
                    let body = originalBody.clone();
                    const uint = body.loadUint(32);
                    if (uint === 0x0f8a7ea5 || uint === 4) {
                      const res = await submitDepositTXHash(hash);
                      authStore.character = res.body.character;
                    }
                  } catch (error) {}
                }
                map[hash] = true;
              }
              localStorage.setItem("checkedTxs", JSON.stringify(map));
            }}
          >
            TEst
          </MainButtonV2>
        )}
      </VFlex>
      <Flex
        style={{
          // height: 84,
          alignItems: "center",
          padding: "12px 16px",
          position: "relative",
          backgroundColor: "#13181a",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        {wallet ? (
          <Flex className="justify-between flex-1 gap-[10px]">
            <Flex
              className="flex-1 items-center gap-[10px] rounded-md simple-btn"
              style={{
                paddingRight: 6,
                border: "1px solid #484848",
              }}
              onClick={async () => {
                const res = await store.confirm({
                  title: "Disconnect",
                  content: "Are you sure you want to disconnect?",
                });
                if (res) {
                  tonConnectUI.disconnect();
                }
              }}
            >
              <WalletSVG />
              <VFlex style={{ flex: 1 }}>
                <SBodyBold>Connected</SBodyBold>
                <SBody style={{ marginTop: -5 }}>
                  {getAddressString(wallet?.account?.address)}
                </SBody>
              </VFlex>
              <RegularCircleCheckSVG fill="#54DAF3" />
            </Flex>
            <MainBorderButtonV2
              style={{ height: 44, width: 64 }}
              onClick={async () => {
                // setIsShowCopied(true);
                copyToClipboard(wallet?.account?.address ?? "");
                // await sleep(1000);
                // setIsShowCopied(false);
              }}
            >
              <CopySVG />
            </MainBorderButtonV2>
            {/* <VFlex
              onClick={() => {
                copyToClipboard(wallet?.account?.address);
              }}
              className="flex-1"
            >
              <SBody600>{`Connected wallet:`}</SBody600>
              <SBody600>{getAddressString(wallet?.account?.address)}</SBody600>
            </VFlex>
            <MainButtonV2
              onClick={() => {
                tonConnectUI.disconnect();
              }}
            >
              Disconnect
            </MainButtonV2> */}
          </Flex>
        ) : (
          <MainButtonV2
            style={{ flex: 1 }}
            onClick={() => {
              open();
            }}
          >
            Connect Wallet
          </MainButtonV2>
        )}
      </Flex>
      <img
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -10,
          opacity: 0.2,
          pointerEvents: "none",
        }}
        src="/img/squares.png"
      />
    </VFlex>
  );
});

export default WithdrawTabPage;
