import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";

import { store } from "../../store/store";
import { MainBorderButtonV2, MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import { S20BodyBold, SBodyBold } from "../Typography";
import BaseDialog from "./BaseDialog";

import styled from "styled-components";
import { REWARD_DATA } from "../../constants";
import { authStore } from "../../store/auth-store";
import { ERewardTypes, ETabs } from "../../types/types";
import { numberInGroups } from "../../utils";

interface BalanceDialogProps {
  style?: CSSProperties;
}

const ID = "BalanceDialog";

const SItemContainer = styled(Flex)`
  background: #00000010;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  height: 50px;
  padding: 0px 16px;
  align-items: center;
  gap: 14px;
`;

const BalanceDialog = observer((props: BalanceDialogProps) => {
  return (
    <BaseDialog
      dialogID={ID}
      storeGetter={() => {
        return store.balanceDialogState;
      }}
      onShow={() => {}}
      onClose={() => {
        store.balanceDialogState = null;
      }}
      style={{
        gap: 8,
        transition: "all 0.3s ease",
        opacity: store.balanceDialogState ? 1 : 0,
      }}
    >
      <VFlex
        style={{
          position: "relative",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <VFlex
          style={{
            border: "1px solid #376F9E",
            borderRadius: 16,
            overflow: "hidden",
            margin: "16px",
            minHeight: 200,
            paddingTop: 20,
            paddingBottom: 40,
          }}
        >
          <Flex className="self-stretch items-center justify-center h-[58px] bg-[#0C1C27]">
            <S20BodyBold>
              {store.balanceDialogState?.title || "Current Balance"}
            </S20BodyBold>
          </Flex>
          {(() => {
            return (
              <div>
                <VFlex
                  style={{
                    alignItems: "stretch",
                    alignSelf: "stretch",
                    padding: "8px 16px",
                    gap: 8,
                  }}
                >
                  {[
                    {
                      type: ERewardTypes.WELL,
                      value: authStore.character?.well_balance ?? 0,
                    },
                    {
                      type: ERewardTypes.TON,
                      value: authStore.character?.ton_balance ?? 0,
                    },
                  ].map(({ type, value }) => {
                    const rewardData = REWARD_DATA[type];
                    return (
                      <SItemContainer style={{}}>
                        <img
                          src={rewardData.icon}
                          style={{
                            width: 46,
                            height: 46,
                          }}
                        />
                        <VFlex className="flex-1">
                          <SBodyBold>{`$${rewardData.title}`}</SBodyBold>
                        </VFlex>
                        <VFlex
                          style={{
                            minWidth: 50,
                            gap: 8,
                          }}
                        >
                          <SBodyBold style={{ color: rewardData.themeColor }}>
                            {numberInGroups(value, 10)}
                          </SBodyBold>
                        </VFlex>
                      </SItemContainer>
                    );
                  })}
                </VFlex>
              </div>
            );
          })()}
          <VFlex className="items-center gap-4 px-8 mt-8">
            <MainButtonV2
              style={{
                minWidth: "320px",
                width: "100%",
                margin: "0px 16px",
              }}
              onClick={() => {
                store.currentTab = ETabs.AIRDROP;
                store.balanceDialogState = null;
              }}
            >
              Withdraw
            </MainButtonV2>
            <MainBorderButtonV2
              style={{
                minWidth: "320px",
                width: "100%",
                margin: "0px 16px",
              }}
              onClick={() => {
                store.balanceDialogState = null;
              }}
            >
              Close
            </MainBorderButtonV2>
          </VFlex>
        </VFlex>
      </VFlex>
    </BaseDialog>
  );
});

export default BalanceDialog;
