import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import Lottie from "react-lottie";
import { store } from "../../store/store";
import BaseDialog from "./BaseDialog";
interface TxInProgressDialogProps {
  style?: CSSProperties;
}

import animationData from "../../lottie/tx-in-progress.json";
import { VFlex } from "../styled/Flex";
import { S18Body, S18BodyBold, S20BodyBold } from "../Typography";

const ID = "TxInProgressDialog";

const TxInProgressDialog = observer((props: TxInProgressDialogProps) => {
  const isShow = store.isShowTxInProgressDialog;
  return (
    <BaseDialog
      dialogID={ID}
      storeGetter={() => {
        return store.isShowTxInProgressDialog;
      }}
      onShow={() => {}}
      onClose={() => {
        store.isShowTxInProgressDialog = false;
      }}
      style={{
        gap: 8,
        transition: "all 0.3s ease",
        opacity: isShow ? 1 : 0,
      }}
    >
      {(() => {
        return (
          <>
            <VFlex
              style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Lottie
                style={{
                  pointerEvents: "none",
                  zIndex: 5,
                }}
                height={200}
                width={200}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
              />
              <img
                style={{
                  height: 60,
                  width: "auto",
                  position: "absolute",
                  zIndex: 6,
                  transform: "translateY(-54px)",
                }}
                src="/img/hex-well-icon.png"
              />
              <S20BodyBold>Transaction in progress</S20BodyBold>
              <S18Body className="mt-3 text-center px-6">
                Keep this window open, this can take up to 60 seconds.
              </S18Body>
              <S18BodyBold style={{ color: "#54DAF3" }}>
                Please don't close the app.
              </S18BodyBold>
            </VFlex>
          </>
        );
      })()}
    </BaseDialog>
  );
});

export default TxInProgressDialog;
