import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import Lottie from "react-lottie";
import animationData from "../lottie/star-burst-v2.json";
import { store } from "../store/store";

interface ClaimedLottieProps {
  style?: CSSProperties;
}

const ClaimedLottie = observer((props: ClaimedLottieProps) => {
  return (
    <Lottie
      style={{
        position: "absolute",
        pointerEvents: "none",
        zIndex: 5,
      }}
      height={store.screenSize.width * 0.8}
      width={store.screenSize.width * 0.8}
      options={{
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
    />
  );
});

export default ClaimedLottie;
