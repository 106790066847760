import { observer } from "mobx-react-lite";
import { CSSProperties, HtmlHTMLAttributes } from "react";
import { Flex } from "./styled/Flex";
interface GradiantButtonProps extends HtmlHTMLAttributes<HTMLDivElement> {
  style?: CSSProperties;
  c1?: string;
  c2?: string;
}

const GradiantButton = observer((props: GradiantButtonProps) => {
  const { style, c1, c2, ...p } = props;
  return (
    <Flex
      style={{
        padding: "8px 6px",
        border: "1px solid #5EA5D6",
        background: `radial-gradient(${c1 || "#163A5400"} 0%, ${c2 || "#5292BF40"} 100%)`,
        borderRadius: 12,
        alignItems: "center",
        justifyContent: "center",
        font: "normal normal 600 14px/18px Montserrat",
        gap: 3,
        ...style,
      }}
      {...p}
    >
      {props.children}
    </Flex>
  );
});

export default GradiantButton;
