import {
  Character,
  DailyRewardResultItem,
  GeneralAPIResBody,
} from "../types/api-types";
import { get, post } from "./api";

export const getCharacter = async () => {
  return (
    await get<GeneralAPIResBody>({
      uri: "/character",
    })
  ).body?.character;
};

export const claimDailyReward = async () => {
  return (
    await post<{
      character: Character;
      rewards: DailyRewardResultItem[];
    }>({
      uri: "/character/claim_daily_reward",
    })
  ).body;
};

export const levelUp = async () => {
  return (
    await post<GeneralAPIResBody>({
      uri: "/character/level_up",
    })
  ).body?.character;
};

export const levelUpItem = async (itemID: string) => {
  return (
    await post<GeneralAPIResBody>({
      uri: "/character/level_up_item",
      body: { item_id: itemID },
    })
  ).body?.character;
};

export const claimPassiveReward = async () => {
  return (
    await post<GeneralAPIResBody>({
      uri: "/character/claim_passive_reward",
    })
  ).body?.character;
};

export const submitDepositTXHash = async (hash: string) => {
  return await post<GeneralAPIResBody>({
    uri: "/character/deposits",
    body: {
      tx_id: hash,
    },
  });
};

export const depositTON = async (amount: number, from: string) => {
  return await post<{
    amount: string;
    payload: string;
    signature: string;
    timestamp: number;
    wallet: string;
  }>({
    uri: "/character/deposits/ton",
    body: {
      amount,
      from,
    },
  });
};
