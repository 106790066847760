import { observer } from "mobx-react-lite";
import { CSSProperties, HtmlHTMLAttributes } from "react";
interface UploadSVGProps extends HtmlHTMLAttributes<any> {
  style?: CSSProperties;
  fill?: string;
}

const WalletSVG = observer((props: UploadSVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      id="Wallet_Icon"
      data-name="Wallet Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      {...p}
    >
      <rect
        id="Rectangle_534"
        data-name="Rectangle 534"
        width="40"
        height="40"
        fill="none"
      />
      <g
        id="Icon_akar-wallet"
        data-name="Icon akar-wallet"
        transform="translate(5.961 5.169)"
      >
        <path
          id="Path_20"
          data-name="Path 20"
          d="M3,8.208A2.208,2.208,0,0,1,5.208,6H22.871a2.208,2.208,0,0,1,2.208,2.208V21.455a2.208,2.208,0,0,1-2.208,2.208H5.208A2.208,2.208,0,0,1,3,21.455Zm0,2.208H25.079"
          fill="none"
          stroke={fill || "#fff"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Path_21"
          data-name="Path 21"
          d="M3,18h7.728a3.216,3.216,0,0,0,3.312,3.349A3.216,3.216,0,0,0,17.351,18h7.728"
          transform="translate(0 -3.191)"
          fill="none"
          stroke={fill || "#fff"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
    </svg>
  );
});

export default WalletSVG;
