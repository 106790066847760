import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";

import { store } from "../../store/store";
import { MainBorderButtonV2, MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import { S14Body, S20BodyBold } from "../Typography";
import BaseDialog from "./BaseDialog";

interface ConfirmDialogProps {
  style?: CSSProperties;
}

const ID = "ConfirmDialog";

const ConfirmDialog = observer((props: ConfirmDialogProps) => {
  return (
    <BaseDialog
      dialogID={ID}
      storeGetter={() => {
        return store.confirmDialogState;
      }}
      onShow={() => {}}
      onClose={() => {
        store.confirmDialogState = null;
      }}
      style={{
        gap: 8,
        transition: "all 0.3s ease",
        opacity: store.confirmDialogState ? 1 : 0,
      }}
    >
      <VFlex
        style={{
          position: "relative",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <VFlex
          style={{
            border: "1px solid #376F9E",
            borderRadius: 16,
            overflow: "hidden",
            margin: "16px",
            height: 300,
          }}
        >
          <Flex className="self-stretch items-center justify-center h-[58px] bg-[#0C1C27]">
            <S20BodyBold>{store.confirmDialogState?.title}</S20BodyBold>
          </Flex>
          <VFlex
            className="self-stretch items-center justify-center flex-1 p-4"
            style={{
              background: "#212832",
            }}
          >
            {typeof store.confirmDialogState?.content === "string" ? (
              <S14Body>{store.confirmDialogState?.content}</S14Body>
            ) : (
              store.confirmDialogState?.content
            )}
          </VFlex>
        </VFlex>
        <MainButtonV2
          style={{
            margin: "0px 16px",
          }}
          onClick={async () => {
            store.confirmDialogState?.resolve?.(true);
            store.confirmDialogState?.onResult?.(true);
            store.confirmDialogState = null;
          }}
        >
          {store.confirmDialogState?.yesText || "Confirm"}
        </MainButtonV2>
        {!store.confirmDialogState?.hideCancel && (
          <MainBorderButtonV2
            style={{
              margin: "16px 16px",
            }}
            onClick={() => {
              store.confirmDialogState?.resolve?.(false);
              store.confirmDialogState?.onResult?.(false);
              store.confirmDialogState = null;
            }}
          >
            {store.confirmDialogState?.yesText || "Cancel"}
          </MainBorderButtonV2>
        )}
      </VFlex>
    </BaseDialog>
  );
});

export default ConfirmDialog;
