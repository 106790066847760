import { SVGProps } from ".";
const MoreSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...p}
    >
      <g transform="translate(0 8)">
        <rect width="32" height="32" transform="translate(0 -8)" fill="none" />
        <g transform="translate(7.761 7.165)">
          <g
            transform="translate(-3.76 -10.165)"
            fill="none"
            stroke={fill || "#fff"}
            stroke-width="1.5"
          >
            <rect width="24" height="22" rx="4" stroke="none" />
            <rect
              x="0.75"
              y="0.75"
              width="22.5"
              height="20.5"
              rx="3.25"
              fill="none"
            />
          </g>
          <path
            d="M10.493,14.985V10.493m0,0V6m0,4.493h4.493m-4.493,0H6"
            transform="translate(-2.253 -9.658)"
            fill="none"
            stroke={fill || "#fff"}
            stroke-linecap="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default MoreSVG;
