import initReactFastclick from "react-fastclick";

import { TonConnectUIProvider } from "@tonconnect/ui-react";
import classNames from "classnames";
import { configure } from "mobx";
import { observer } from "mobx-react";
import { useEffect } from "react";

import { useExpand } from "@vkruglikov/react-telegram-web-app";
import "./App.css";
import AuthLoader from "./components/AuthLoader/AuthLoader";
import BottomTabBar from "./components/BottomTabBar";
import ClickPopupTextContainer from "./components/ClickPopupTextContainer";
import BalanceDialog from "./components/Dialog/BalanceDialog";
import BuyMagicCratesDialog from "./components/Dialog/BuyMagicCratesDialog";
import BuyMagicCratesResultDialog from "./components/Dialog/BuyMagicCratesResultDialog";
import ClaimedDialog from "./components/Dialog/ClaimedDialog";
import ConfirmDialog from "./components/Dialog/ConfirmDialog";
import DailyRewardDialog from "./components/Dialog/DailyRewardDialog";
import HatchedDialog from "./components/Dialog/HatchedDialog";
import LoadingDialog from "./components/Dialog/LoadingDialog";
import LuckyPickDialog from "./components/Dialog/LuckyPickDialog";
import MagicCratesDialog from "./components/Dialog/MagicCratesDialog";
import MagicCratesResultDialog from "./components/Dialog/MagicCratesResultDialog";
import MissionDialog from "./components/Dialog/MissionDialog";
import PromptDialog from "./components/Dialog/PromptDialog";
import ReflectDialog from "./components/Dialog/ReflectDialog";
import TxInProgressDialog from "./components/Dialog/TxInProgressDialog";
import WalletConnectedDialog from "./components/Dialog/WalletConnectedDialog";
import WithdrawalHistoryDialog from "./components/Dialog/WithdrawalHistoryDialog";
import WithdrawalRequestedDialog from "./components/Dialog/WithdrawalRequestedDialog";
import WithdrawDialog from "./components/Dialog/WithdrawDialog";
import Loader from "./components/Loader";
import { SCircularGradiantBox } from "./components/SCircularGradiantBox";
import { VFlex } from "./components/styled/Flex";
import GlobalCSS from "./components/styled/GlobalStyle";
import FriendsTabPage from "./components/tab-pages/FriendsTabPage";
import MainTabPage from "./components/tab-pages/MainTabPage";
import MissionsTabPage from "./components/tab-pages/MissionsTabPage";
import SkillsTabPage from "./components/tab-pages/SkillsTabPage";
import WithdrawTabPage from "./components/tab-pages/WithdrawTabPage";
import { store } from "./store/store";
import { ETabs } from "./types/types";

initReactFastclick();

configure({
  enforceActions: "never",
});

function App() {
  const [isExpanded, expand] = useExpand();
  useEffect(() => {
    const resizeListener = () => {
      requestAnimationFrame(() => {
        store.setScreenSize({
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        });
      });
    };
    window.addEventListener("resize", resizeListener);
    if (!isExpanded) {
      expand?.();
    }
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);
  return (
    <TonConnectUIProvider manifestUrl="https://wellness-clicker-dev.pages.dev/tonconnect-manifest.json">
      <div
        className={classNames("flex flex-col items-stretch h-full relative")}
        style={{ overflow: "hidden", maxWidth: "100vw", maxHeight: "100vh" }}
      >
        <Loader />
        <AuthLoader />
        <GlobalCSS />
        <VFlex
          className="flex-1 z-10 overflow-y-hidden"
          style={{ marginBottom: store.bottomTabBarHeight - 12 }}
        >
          {(() => {
            if (store.isOpenPage === "airdrop") {
              return <WithdrawTabPage />;
            }
            if (store.isOpenPage === "friend") {
              return <FriendsTabPage />;
            }
            switch (store.currentTab) {
              case ETabs.HOME:
                return <MainTabPage />;
              case ETabs.SKILLS:
                return <SkillsTabPage />;
              case ETabs.MISSIONS:
                return <MissionsTabPage />;
              case ETabs.AIRDROP:
                return <WithdrawTabPage />;
              case ETabs.FRIENDS:
                return <FriendsTabPage />;
            }
          })()}
        </VFlex>
        <BottomTabBar />
        <ClickPopupTextContainer />
        <SCircularGradiantBox
          style={{
            transform: "scale(3, 4) translateY(-10%)",
            opacity: 0.5,
          }}
        />
      </div>
      <PromptDialog />
      <ConfirmDialog />
      <ReflectDialog />
      <HatchedDialog />
      <DailyRewardDialog />
      <LuckyPickDialog />
      <MagicCratesDialog />
      <MagicCratesResultDialog />
      <BuyMagicCratesDialog />
      <BuyMagicCratesResultDialog />
      <LoadingDialog />
      <MissionDialog />
      <ClaimedDialog />
      <WalletConnectedDialog />
      <WithdrawDialog />
      <WithdrawalHistoryDialog />
      <WithdrawalRequestedDialog />
      <BalanceDialog />
      <TxInProgressDialog />
    </TonConnectUIProvider>
  );
}

export default observer(App);
