import { observer } from "mobx-react-lite";
import { CSSProperties, useRef } from "react";
import { claimMission } from "../../api/mission";
import { authStore } from "../../store/auth-store";
import { store } from "../../store/store";
import { numberInGroups } from "../../utils";
import { MainButton, MainButtonV2 } from "../MainButton";
import MobxCountdownTimer from "../MobxCountdownTimer";
import { SCircularGradiantBox } from "../SCircularGradiantBox";
import { Flex, VFlex } from "../styled/Flex";
import CheckedSVG from "../SVG/CheckedSVG";
import DialogCloseBtnSVG from "../SVG/DialogCloseBtnSVG";
import {
  S14Body,
  S18Body600,
  S18BodyBold,
  S20Body,
  S20Body800,
  S20BodyBold,
  S24Body600,
  SBody800,
} from "../Typography";
import BaseDialog from "./BaseDialog";
interface MissionDialogProps {
  style?: CSSProperties;
}

const ID = "MissionDialog";

const MissionDialog = observer((props: MissionDialogProps) => {
  const timeRef = useRef(0);
  return (
    <BaseDialog
      dialogID={ID}
      storeGetter={() => {
        return store.missionDialogState;
      }}
      onShow={() => {
        timeRef.current = Date.now();
      }}
      onClose={() => {
        if (Date.now() - timeRef.current < 1000) return;
        store.missionDialogState = null;
      }}
      style={{
        gap: 8,
        transition: "all 0.3s ease",
        opacity: store.missionDialogState ? 1 : 0,
      }}
    >
      {(() => {
        if (store.missionDialogState?.isClaimed) {
          return (
            <VFlex
              style={{
                position: "relative",
                flex: 1,
                justifyContent: "center",
              }}
            >
              <VFlex
                style={{
                  border: "1px solid #376F9E",
                  borderRadius: 16,
                  overflow: "hidden",
                  margin: "16px",
                  height: 229,
                }}
              >
                <Flex className="self-stretch items-center justify-center h-[58px] bg-[#0C1C27]">
                  <S20BodyBold>Claimed!</S20BodyBold>
                </Flex>
                <VFlex
                  className="self-stretch items-center justify-center flex-1"
                  style={{
                    background: "#1f262e",
                  }}
                >
                  <img style={{ height: 69, width: 69 }} src="/img/orb.png" />
                  <Flex>
                    <S20BodyBold>
                      {`+${numberInGroups(store.missionDialogState?.mission.reward.amount)}`}
                    </S20BodyBold>
                    <S20Body>&nbsp;ENERGY</S20Body>
                  </Flex>
                </VFlex>
              </VFlex>
              <MainButtonV2
                style={{
                  margin: "0px 16px",
                }}
                onClick={() => {
                  store.missionDialogState = null;
                }}
                large
              >
                Done
              </MainButtonV2>
            </VFlex>
          );
        }
        return (
          <VFlex
            style={{
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            <DialogCloseBtnSVG
              className="simple-btn"
              onClick={() => {
                if (Date.now() - timeRef.current < 1000) return;
                store.missionDialogState = null;
              }}
              style={{
                alignSelf: "flex-end",
                margin: 16,
              }}
            />
            <VFlex
              style={{
                height: 400,
                borderRadius: "12px 12px 0px 0px",
                backgroundColor: "#172f41",
                alignItems: "center",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <SCircularGradiantBox
                style={{
                  width: "200%",
                  transform: "translateY(-50%)",
                }}
              />
              <VFlex
                style={{
                  zIndex: 1,
                  marginTop: 20,
                  alignSelf: "stretch",
                  flex: 1,
                }}
              >
                <S20Body800 style={{ textAlign: "center" }}>
                  {store.missionDialogState?.isShowAttention
                    ? "Attention!"
                    : store.missionDialogState?.mission.name}
                </S20Body800>
                {store.missionDialogState?.isShowAttention ? (
                  <>
                    <S14Body
                      style={{
                        color: "#BFF5FF",
                        textAlign: "center",
                        margin: "8px 24px",
                      }}
                    >
                      By claiming the reward, you confirm mission completion.
                      Any false claims may result in a penalty of double the
                      reward, and repeated offenses can lead to suspension or
                      permanent ban.
                    </S14Body>
                    <Flex
                      style={{
                        alignItems: "center",
                        alignSelf: "stretch",
                        margin: "0px 28px",
                        justifyContent: "center",
                        backgroundColor: "#1A2F40",
                        borderRadius: 8,
                        marginTop: 12,
                        padding: "9px 11px",
                      }}
                    >
                      <VFlex
                        style={{
                          alignItems: "center",
                          flex: 1,
                          borderRight: "1px solid #405A6D",
                        }}
                      >
                        <SBody800>Reward</SBody800>
                        <Flex style={{ alignItems: "center" }}>
                          <img
                            src="/img/orb.png"
                            style={{ height: 46, width: 46 }}
                          />
                          <S24Body600
                            style={{
                              color: "#BFF5FF",
                            }}
                          >{`+${numberInGroups(store.missionDialogState?.mission.reward.amount)}`}</S24Body600>
                        </Flex>
                      </VFlex>
                      <VFlex style={{ alignItems: "center", flex: 1 }}>
                        <SBody800>Penalty</SBody800>
                        <Flex style={{ alignItems: "center" }}>
                          <img
                            src="/img/orb.png"
                            style={{ height: 46, width: 46 }}
                          />
                          <S24Body600
                            style={{
                              color: "#F28C9B",
                            }}
                          >{`-${numberInGroups(store.missionDialogState?.mission.reward.amount * 2)}`}</S24Body600>
                        </Flex>
                      </VFlex>
                    </Flex>
                    <div className="flex-1" />
                    <MainButton
                      large
                      style={{
                        margin: "0px 24px",
                      }}
                      onClick={async () => {
                        // if (!store.missionDialogState) return;
                        // store.missionDialogState.verifyFrom = Date.now();
                        // store.missionDialogState.isShowAttention = false;
                        if (!store.missionDialogState) return;
                        store.isShowLoadingDialog = true;
                        try {
                          const res = await claimMission(
                            store.missionDialogState.mission.id,
                          );
                          authStore.character = res.body.character;
                          store.missionDialogState.isClaimed = true;
                        } catch (error) {
                          store.showErrorDialog(error);
                        }
                        store.isShowLoadingDialog = false;
                      }}
                    >
                      <S18BodyBold>Claim Reward</S18BodyBold>
                    </MainButton>
                    <S14Body className="m-4 text-center">
                      Account must be at least 5 days old to proceed.
                    </S14Body>
                  </>
                ) : (
                  <>
                    <Flex
                      style={{
                        height: 61,
                        gap: 8,
                        alignItems: "center",
                        alignSelf: "stretch",
                        margin: "0px 28px",
                        justifyContent: "center",
                        backgroundColor: "#1A2F40",
                        borderRadius: 8,
                        marginTop: 22,
                      }}
                    >
                      <img
                        src="/img/orb.png"
                        style={{ height: 46, width: 46 }}
                      />
                      <S24Body600>{`+${numberInGroups(store.missionDialogState?.mission.reward.amount ?? 0)}`}</S24Body600>
                    </Flex>
                    {store.missionDialogState?.verifyFrom ? (
                      <>
                        {store.missionDialogState?.verified ? (
                          <Flex className="self-center m-5 gap-1 items-center">
                            <S18Body600>Verified</S18Body600>
                            <CheckedSVG />
                          </Flex>
                        ) : (
                          <Flex className="self-center m-5">
                            <S18Body600>Verifying&nbsp;</S18Body600>
                            <MobxCountdownTimer
                              deadline={
                                store.missionDialogState?.verifyFrom - 1
                              }
                              onTimeout={() => {
                                if (!store.missionDialogState) return;
                                store.missionDialogState.verified = true;
                              }}
                              Wrapper={S18Body600}
                            />
                          </Flex>
                        )}
                        <MainButton
                          large
                          style={{
                            margin: "28px",
                          }}
                          onClick={async () => {
                            if (!store.missionDialogState) return;
                            if (
                              !store.isShowLoadingDialog &&
                              store.missionDialogState.verified
                            ) {
                              store.isShowLoadingDialog = true;
                              try {
                                const res = await claimMission(
                                  store.missionDialogState.mission.id,
                                );
                                authStore.character = res.body.character;
                                store.missionDialogState.isClaimed = true;
                              } catch (error) {
                                store.showErrorDialog(error);
                              }
                              store.isShowLoadingDialog = false;
                            } else {
                              store.missionDialogState.isShowAttention = true;
                            }
                          }}
                          disabled={!store.missionDialogState?.verified}
                        >
                          Claim
                        </MainButton>
                      </>
                    ) : (
                      <>
                        <div className="flex-1" />
                        <MainButton
                          large
                          style={{
                            margin: "28px",
                          }}
                          onClick={() => {
                            if (!store.missionDialogState) return;
                            window.open(
                              store.missionDialogState.mission.redirect?.url,
                              "_blank",
                            );
                            store.missionDialogState.isShowAttention = true;
                          }}
                        >
                          Go
                        </MainButton>
                      </>
                    )}
                  </>
                )}
              </VFlex>
              <img
                style={{ opacity: 0.3 }}
                className="absolute top-0 right-0 h-full w-full"
                src="/img/squares2.png"
              />
            </VFlex>
          </VFlex>
        );
      })()}
    </BaseDialog>
  );
});

export default MissionDialog;
