import { SVGProps } from ".";
const FriendsSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...p}
    >
      <g transform="translate(0 8)">
        <rect width="32" height="32" transform="translate(0 -8)" fill="none" />
        <g transform="translate(3.018 -5.992)">
          <path
            d="M17.157,28.828V26.719A4.2,4.2,0,0,0,12.982,22.5H5.675A4.2,4.2,0,0,0,1.5,26.719v2.109"
            transform="translate(0 -5.344)"
            fill="none"
            stroke={fill || "#fff"}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            d="M15.1,8.719A4.175,4.175,0,1,1,10.925,4.5,4.2,4.2,0,0,1,15.1,8.719Z"
            transform="translate(-1.597)"
            fill="none"
            stroke={fill || "#fff"}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            d="M28.631,12v6.328m3.131-3.164H25.5"
            transform="translate(-7.299 -2.227)"
            fill="none"
            stroke={fill || "#fff"}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default FriendsSVG;
