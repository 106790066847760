import { SVGProps } from ".";
const RegularCircleCheckSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...p}
    >
      <path
        d="M12,2.25A9.75,9.75,0,1,1,2.25,12,9.75,9.75,0,0,1,12,2.25ZM12,24A12,12,0,1,0,0,12,12,12,0,0,0,12,24ZM17.3,9.8a1.124,1.124,0,0,0-1.589-1.589l-5.2,5.2-2.2-2.2A1.124,1.124,0,0,0,6.712,12.8l3,3a1.12,1.12,0,0,0,1.589,0Z"
        fill={fill || "#54daf3"}
      />
    </svg>
  );
};

export default RegularCircleCheckSVG;
