import { ERewardTypes } from "./types";

export type GameConfig = {
  passive_claim_max_seconds: number;
  character_table: {
    level_up_exp_required: string[];
    level_exp_earn_per_hour: string[];
  };
  item_table: Record<
    Rarity,
    {
      level_up_energy: string[];
      increase_exp_per_hour: string[];
    }
  >;
  item_templates: ItemTemplate[];
  character: Character;
  egg_table: Record<
    Rarity,
    {
      reset_energy: number;
      lock_time_sec: number;
    }
  >;
  missions: Mission[];
  referral_config: ReferralConfig;
  daily_reward_table: DailyReward[];
  lucky_pick_table: LuckyPickTable;
  item_categories: { id: number; category_name: string }[];
};

export type DailyReward = {
  type: string;
  amount?: number;
  rarity?: Rarity;
  items?: DailyRewardItem[];
};

export type DailyRewardItem = {
  type: "energy" | "item" | "one_of";
  amount?: number;
  rarity?: Rarity;
};

export type LuckyPickTable = {
  energy_rewards: LuckyPickReward[];
  ton_rewards: LuckyPickReward[];
  well_rewards: LuckyPickReward[];
  jackpot_bps: number;
  ton_cost: number;
  well_cost: number;
};

export type LuckyPickReward = {
  type: string;
  multiplier: number;
  amount: number;
};

export type Mission = {
  id: string;
  name: string;
  img_url: string;
  redirect?: {
    type: string;
    url: string;
  };
  reward: {
    type: string;
    amount: number;
  };
};

export type ReferralConfig = {
  referrer_reward: {
    type: ERewardTypes;
    amount: number;
  };
  referee_reward: {
    type: ERewardTypes;
    amount: number;
  };
};

export type ItemTemplate = {
  item_name: string;
  id: string;
  category: string;
  rarity: Rarity;
  img_url: string | null;
};

export type Character = {
  avatar_url: string;
  claimed_mission_rewards: ClaimedMissionReward[];
  continuous_checkin_days: number;
  energy: number;
  exp: number;
  is_cheater: boolean;
  items: CharacterItem[];
  last_daily_reward_claimed_at: number;
  last_passive_reward_claim: number;
  last_sync: number;
  level: number;
  lucky_pick_play_log: null;
  name: string;
  next_daily_reward_available_at: number;
  referral_code: string;
  referred_by: { tg_user_id: string; name?: string };
  referree: Referree[];
  tg_user_id: string;
  ton_address: string;
  ton_balance: string;
  well_access_token: string;
  well_balance: string;
  well_user_id: string;
  eggs: Egg[];
  energy_per_hour: number;
  lucky_pick_energy_cost: number;
  lucky_pick_ton_cost: number;
  lucky_pick_well_cost: number;
  standard_eggs: Egg[];
};

export type ClaimedMissionReward = {
  claimed_at: number;
  mission_id: string;
  reward: {
    amount: number;
    type: string;
  };
};

export type Referree = {
  reward: {
    amount: number;
    type: string;
  };
  reward_claimed: boolean;
  tg_user_id: string;
  name: string;
};

export enum Rarity {
  BASIC = "basic",
  RARE = "rare",
  MYTHIC = "mythic",
}

export type Egg = {
  id: string;
  is_standard: boolean;
  rarity: Rarity;
  unlock_at: number;
};

export type CharacterItem = {
  category: string;
  effects: string[];
  id: string;
  item_id: string;
  level: number;
  rarity: Rarity;
};

export type GeneralAPIResBody = { character: Character };

export interface LuckyPickAPIResBody extends GeneralAPIResBody {
  reward: {
    amount: string;
    character_id: string;
    claimed_at: number;
    currency: ERewardTypes;
    id: string;
    is_jackpot: boolean;
  };
}

export interface HatchEggAPIResBody extends GeneralAPIResBody {
  hatched_item: {
    id: string;
    level: number;
    item_id: string;
    category: string;
    rarity: Rarity;
    effects: string[];
  };
}

export type LuckyPickPools = {
  ton_pool: {
    balance: number;
    last_updated_at: number;
    reward_currency: ERewardTypes;
  };
  well_pool: {
    balance: number;
    last_updated_at: number;
    reward_currency: ERewardTypes;
  };
};

export type DailyRewardResultItem = {
  type: "item";
  id: string;
  rarity: Rarity;
  category: string;
  effects: string[];
  level: number;
  item_id: string;
};

export type Withdrawal = {
  amount: number;
  currency: string;
  dest: string;
  id: string;
  signed_at: number;
  user_id: string;
};

export type WithdrawalsResponse = {
  withdrawals: Withdrawal[];
};
