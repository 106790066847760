import { SVGProps } from ".";
const ChevaronRightSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...p}
    >
      <g id="Group_471" data-name="Group 471" transform="translate(-321 -35)">
        <path
          id="Icon_ion-ios-arrow-forward"
          data-name="Icon ion-ios-arrow-forward"
          d="M16.275,12.49,11.511,7.729a.9.9,0,0,1,0-1.271.907.907,0,0,1,1.275,0l5.4,5.395a.9.9,0,0,1,.026,1.241l-5.421,5.432a.9.9,0,1,1-1.275-1.271Z"
          transform="translate(313.754 30.804)"
          fill={fill || "#fff"}
        />
        <rect
          id="Rectangle_665"
          data-name="Rectangle 665"
          width="16"
          height="16"
          transform="translate(321 35)"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default ChevaronRightSVG;
