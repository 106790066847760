import { TonClient } from "ton";
import { IS_PROD } from "../constants";
import { useInit } from "./hook";

export function useTonClient() {
  return useInit(
    async () =>
      new TonClient({
        endpoint: IS_PROD
          ? "https://ton-mainnet.core.chainstack.com/6486f9e0e7da30fb62c1827335068f2e/api/v2/jsonRPC"
          : "https://ton-testnet.core.chainstack.com/ba1bb01f436f8702ec9cf07d90325c77/api/v2/jsonRPC",
      }),
  );
}
