import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import styled from "styled-components";
import { MAGIC_CRATE_DATA } from "../../constants";
import { store } from "../../store/store";
import { MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import StylishContainer from "../StylishContainer";
import { S18Body600, S34BodyBold, SBody, SBody600 } from "../Typography";
import BaseDialogUI from "./BaseDialogUI";
interface BuyMagicCratesResultDialogProps {
  style?: CSSProperties;
}

const ID = "BuyMagicCratesResultDialog";

const SBoxItemContainer = styled(Flex)`
  background: #ffffff10 0% 0% no-repeat padding-box;
  border: 1px solid #82b3d6;
  border-radius: 9px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  align-self: stretch;
  height: 86px;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;
`;

const SBoxPriceContainer = styled(Flex)`
  background: #172937 0% 0% no-repeat padding-box;
  border: 1px solid #5ea5d6;
  border-radius: 8px;
  opacity: 1;
  height: 40px;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const BuyMagicCratesResultDialog = observer(
  (props: BuyMagicCratesResultDialogProps) => {
    return (
      <BaseDialogUI
        dialogID={ID}
        storeGetter={() => {
          return store.buyMagicCratesResultDialogState;
          // return true;
        }}
        onShow={() => {}}
        onClose={() => {
          store.buyMagicCratesResultDialogState = null;
        }}
        style={{
          padding: "8px 16px",
          gap: 8,
        }}
        containerStyle={{ flex: 1 }}
      >
        {(() => {
          if (!store.buyMagicCratesResultDialogState) return null;
          return (
            <>
              <VFlex
                style={{
                  backgroundColor: "#1c3445",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderRadius: 12,
                  overflow: "hidden",
                }}
              >
                <Flex
                  style={{
                    alignItems: "center",
                    height: 58,
                    gap: 6,
                  }}
                >
                  <img src="/img/magic-crate-icon.svg" />
                  <SBody style={{ fontWeight: "bold" }}>Magic Crates</SBody>
                </Flex>
              </VFlex>
              <S18Body600 className="text-center">Congratulations!</S18Body600>
              <S18Body600 className="text-center mt-[-10px]">
                you successfully purchased the Magic Crates.
              </S18Body600>
              <VFlex className="flex-1 items-center">
                <StylishContainer
                  style={{
                    width: 212,
                    height: 274,
                  }}
                >
                  <img
                    style={{ width: "90%", height: "auto" }}
                    src={
                      MAGIC_CRATE_DATA[
                        store.buyMagicCratesResultDialogState?.magicCrateType
                      ]?.icon
                    }
                  />
                  <S34BodyBold>{`x${store.buyMagicCratesResultDialogState?.unitAmount}`}</S34BodyBold>
                  <SBody600>Mythic Magic Crates</SBody600>
                </StylishContainer>
                <SBody className="text-center mt-3">
                  Results will be revealed once the current round finishes.
                </SBody>
              </VFlex>
              <MainButtonV2
                className="self-stretch"
                large
                onClick={() => {
                  if (!store.buyMagicCratesResultDialogState) return;
                  store.buyMagicCratesResultDialogState = null;
                }}
              >
                Done
              </MainButtonV2>
            </>
          );
        })()}
      </BaseDialogUI>
    );
  },
);

export default BuyMagicCratesResultDialog;
