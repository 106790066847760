import { observer } from "mobx-react";
import { now } from "mobx-utils";
import { useRef } from "react";
import { getTimeLeftObj } from "../utils";
import { Flex } from "./styled/Flex";

const MobxCountdownTimer = observer(
  (props: {
    Wrapper?: any;
    style?: any;
    deadline: number;
    onTimeout?: () => void;
    formatter?: (d: number) => string;
    finished?: any;
  }) => {
    const { finished, Wrapper, deadline, onTimeout, style, ...p } = props;
    const isInit = useRef(false);
    const isCalledTimeout = useRef(false);
    if (isInit.current && !isCalledTimeout.current && deadline < now()) {
      isCalledTimeout.current = true;
      onTimeout && onTimeout();
    }
    isInit.current = true;
    const leftMS = deadline - now();
    const obj = getTimeLeftObj(leftMS);
    if (!obj) {
      return finished;
    }
    const processNumber = (d) => {
      return `${d}`.padStart(2, "0");
    };
    const Component = Wrapper || Flex;
    let str = "";
    if (leftMS > 60 * 60 * 1000) {
      str = `${processNumber(obj.h)}h${processNumber(obj.m)}m`;
    } else {
      str = `${processNumber(obj.m)}m${processNumber(obj.s)}s`;
    }
    return (
      <Component style={{ alignItems: "center", ...style }} {...p}>
        {str}
      </Component>
    );
  },
);

export default MobxCountdownTimer;
