import { HatchEggAPIResBody } from "../types/api-types";
import { post } from "./api";

export const hatchAnEgg = async (eggID: string) => {
  return post<HatchEggAPIResBody>({ uri: `/character/eggs/${eggID}/hatch` });
};

export const hatchAnEggImmediately = async (eggID: string) => {
  return post<HatchEggAPIResBody>({
    uri: `/character/eggs/${eggID}/hatch_immediately`,
  });
};
