import _ from "lodash";
import AirdropSVG from "./components/SVG/AirdropSVG";
import FriendsSVG from "./components/SVG/FriendsSVG";
import HomeSVG from "./components/SVG/HomeSVG";
import MissionsSVG from "./components/SVG/MissionsSVG";
import MoreSVG from "./components/SVG/MoreSVG";
import SkillSVG from "./components/SVG/SkillSVG";
import { Rarity } from "./types/api-types";
import { EMagicCrateTypes, ERewardTypes, ETabs } from "./types/types";

export const ENV = (process.env.REACT_APP_ENV || "").trim();

export const IS_PROD = ENV === "prod";

export const S_MS = 1000;
export const M_MS = S_MS * 60;
export const H_MS = M_MS * 60;
export const DAY_MS = H_MS * 24;

export const WIDTH_SHORTEN_THRESHOLD = 1024;
export const WIDTH_THRESHOLD = 768;
export const MAX_DESKTOP_WIDTH = 1100;

export const getLtMediaQuery = (size: number | string) =>
  `@media only screen and (max-width: ${size}px)`;

export const XS_WIDTH = 600;
export const SM_WIDTH = 960;
export const MD_WIDTH = 1280;
export const LG_WIDTH = 1920;

export const MediaQueryMobileOnly = getLtMediaQuery(WIDTH_THRESHOLD);
export const MediaQueryShortenOnly = getLtMediaQuery(
  WIDTH_SHORTEN_THRESHOLD + 1,
);

export const MediaQueryXSOnly = getLtMediaQuery(XS_WIDTH);
export const MediaQuerySMOnly = getLtMediaQuery(SM_WIDTH);
export const MediaQueryMDOnly = getLtMediaQuery(MD_WIDTH);
export const MediaQueryLGOnly = getLtMediaQuery(LG_WIDTH);

export const COLORS = {
  grey0: "#FFFFFF50",
  grey1: "#3A4142",
  grey2: "#2E3435",
  grey3: "#1A1E1F",
  dark: "#0E0C34",
  purple1: "#D585FF",
  purple2: "#BF77E6",
  purple3: "#9F63BF",
  skyblue: "#64CCF7",
  blue1: "#a4d9f6",
  blue2: "#80c9f2",
  blue3: "#69b7e3",
  green1: "#8BFCE5",
  green2: "#7DE3CF",
  green3: "#68BDAC",
  pink: "#FCC5B1",
  orange: "#FCD4A4",
  alert: "#DE3146",
  darkBlack: "#050505",
  kubzbg: "#4a7aa7",
  brightGreen: "#26dd6b",
  main: "white",
  highlight: "#fdb720",
  shadedHighlight: "#91651d",
  highlight2: "rgb(239, 182, 21)",
  secondary: "#b5b8fc",
  primary2: "#54DAF3",
  purpleV2: "#AF6AFE",

  white100: "#FFFFFF",
  white80: "#FFFFFFCC",
  white60: "#FFFFFF99",
  white40: "#FFFFFF66",
  white15: "#FFFFFF26",
  white10: "#FFFFFF1A",
  white5: "#FFFFFF0D",

  yellow100: "#FFC671",
  yellow60: "#FFC67199",
  yellow40: "#FFC67166",
  yellow15: "#FFC67126",
  yellow10: "#FFC6711A",
  yellow5: "#FFC6710D",

  super100: "#FFBF43",
};

export const TabData = {
  [ETabs.HOME]: {
    icon: HomeSVG,
    title: "Home",
    iconStyle: null,
    isComingSoon: false,
  },
  [ETabs.SKILLS]: {
    icon: SkillSVG,
    title: "Skills",
    iconStyle: null,
    isComingSoon: false,
  },
  [ETabs.MISSIONS]: {
    icon: MissionsSVG,
    title: "Missions",
    iconStyle: null,
    isComingSoon: false,
  },
  [ETabs.LAUNCH]: {
    icon: MissionsSVG,
    title: "Launch",
    iconStyle: null,
    isComingSoon: false,
  },
  [ETabs.MORE]: {
    icon: MoreSVG,
    title: "More",
    iconStyle: null,
    isComingSoon: false,
  },
  [ETabs.FRIENDS]: {
    icon: FriendsSVG,
    title: "Friends",
    iconStyle: null,
    isComingSoon: false,
  },
  [ETabs.AIRDROP]: {
    icon: AirdropSVG,
    title: "Withdraw",
    iconStyle: null,
    isComingSoon: false,
  },
};

export const BOTTOM_TAB_ARRAY = [
  ETabs.HOME,
  ETabs.SKILLS,
  ETabs.MISSIONS,
  ETabs.FRIENDS,
  ETabs.AIRDROP,
  // ETabs.LAUNCH,
];

export const REWARD_DATA = {
  [ERewardTypes.TON]: {
    icon: "/img/ton-coin.png",
    groupIcon: "/img/group-ton-coin.png",
    title: "TON",
    themeColor: "#0395CD",
  },
  [ERewardTypes.WELL]: {
    icon: "/img/well-coin.png",
    groupIcon: "/img/group-well-coin.png",
    title: "WELL",
    themeColor: "#2977F8",
  },
  [ERewardTypes.Energy]: {
    icon: "/img/orb.png",
    groupIcon: "/img/group-orb.png",
    title: "Energy",
    themeColor: "#7A79CB",
  },
};

export const MAGIC_CRATE_DATA = {
  [EMagicCrateTypes.Basic]: {
    icon: "/img/basic-box.png",
    title: "Basic",
  },
  [EMagicCrateTypes.Rare]: {
    icon: "/img/rare-box.png",
    title: "Rare",
  },
  [EMagicCrateTypes.Mythic]: {
    icon: "/img/mythic-box.png",
    title: "Mythic",
  },
};

export const DARKEN_DIAGONAL_GRADIANT =
  "linear-gradient(-20deg, #1c3241 0%, #0b151c 50%, #1c3241 100%)";

export const TAB_BTN_STYLES = {
  active: {
    background: "linear-gradient(-20deg,#5ea5d6 10%,#255170 50%,#5ea5d6 90%)",
    border: "1px solid #5EA5D6",
  },
  inactive: {
    background: "linear-gradient(-30deg,#1c3241 20%,#0d1921 50%,#1c3241 80%)",
    border: "1px solid #3F5A6D",
  },
};

export const EGG_INFO = {
  [Rarity.BASIC]: {
    title: _.upperFirst(Rarity.BASIC),
    img: "/img/egg1.png",
  },
  [Rarity.RARE]: {
    title: _.upperFirst(Rarity.RARE),
    img: "/img/egg2.png",
  },
  [Rarity.MYTHIC]: {
    title: _.upperFirst(Rarity.MYTHIC),
    img: "/img/egg3.png",
  },
};

export const TG_BOT_URL = IS_PROD
  ? "https://t.me/wellecobot"
  : "https://t.me/wellness_clicker_dev_bot";

export const ITEM_CATEGORIES = [
  {
    id: 0,
    category_name: "Yoga Pose",
  },
  {
    id: 1,
    category_name: "Weight Traning",
  },
  {
    id: 2,
    category_name: "Sports",
  },
  {
    id: 3,
    category_name: "Equipments",
  },
];

export const CONTRACT_ADDR = IS_PROD
  ? "EQCvsWIFeOn8WOadxjxE_gEOQp_EoKfvwKCme0gFXuibcW3u"
  : "EQAnfzoXtHYs7nlpYl1hBBYEyQWyhyV7M8SiNN-c0pVZ5Pyb";

export const TOKEN_ADDR = "EQBEQh9j81QTasIu5533EQ2KRSB2v1kjXfn5GPvl8D_8li2v";

export const TON = BigInt(1000000000);

export const ETH = BigInt(1000000000000000000);

export const GAS_FEE = {
  depositTON: ((BigInt(6) * TON) / BigInt(100)).toString(),
  withdrawTON: ((BigInt(2) * TON) / BigInt(100)).toString(),
  withdrawToken: ((BigInt(12) * TON) / BigInt(100)).toString(),
  depositToken: ((BigInt(6) * TON) / BigInt(100)).toString(),
};

/*
const int error::unknown_action = 700;
const int error::wrong_operator = 701;
const int error::wrong_sender = 702;
const int error::not_enough_coins = 703;
const int error::invalid_signature = 704;
const int error::invalid_nonce = 705;
const int error::signature_expired = 706;
const int error::jetton_wallet_not_set = 707;
const int error::invalid_jetton = 708;
const int error::reach_withdraw_limit = 709;
*/
