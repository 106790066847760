import { observer } from "mobx-react-lite";
import { now } from "mobx-utils";
import { CSSProperties } from "react";
import styled from "styled-components";
import { claimDailyReward } from "../../api/character";
import { authStore } from "../../store/auth-store";
import { store } from "../../store/store";
import { DailyReward } from "../../types/api-types";
import { MainButton } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import CheckedSVG from "../SVG/CheckedSVG";
import { S12Body, S12BodyBold, S18BodyBold, SBody } from "../Typography";
import BaseDialogUI from "./BaseDialogUI";
interface DailyRewardDialogProps {
  style?: CSSProperties;
}

const ID = "DailyRewardDialog";

const STrapezoidBottomBox = styled(Flex)`
  width: 70%;
  height: 18px;
  position: absolute;
  background-color: #5292bf;
  transform: perspective(10px) rotateX(8deg);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
`;

const DayComponent = observer(
  (p: {
    day: number;
    isHighlighted: boolean;
    isChecked: boolean;
    item: DailyReward;
  }) => {
    const { day, isHighlighted, item, isChecked } = p;
    return (
      <VFlex
        style={{
          aspectRatio: "1/1",
          width: "100%",
          borderRadius: 6,
          padding: "2px 6px",
          alignItems: "center",
          background: isHighlighted
            ? "linear-gradient(100deg, #5292bf 0%, #2a5778 50%, #5292bf 100%)"
            : "linear-gradient(100deg, #1e3647 0%, #12202b 40%, #12202b 65%, #1e3647 100%)",
          position: "relative",
          border: isHighlighted ? "1px solid #54DAF3" : "none",
        }}
      >
        <Flex
          style={{
            justifyContent: "space-between",
            alignSelf: "stretch",
          }}
        >
          <S12Body>{`Day ${day}`}</S12Body>
          {isChecked && <CheckedSVG />}
        </Flex>
        <img
          style={(() => {
            if (item.type === "energy") {
              return { width: 40, height: 40 };
            }
            return { width: 50, height: 50 };
          })()}
          src={(() => {
            if (item.type === "energy") {
              return "/img/orb.png";
            }
            if (item.type === "item") {
              return "/img/egg.png";
            }
            return "/img/daily-item.png";
          })()}
        />
        {item.amount && (
          <>
            <S12BodyBold className="font-bold z-10 bottom-[-1px] absolute">
              {item.amount}
            </S12BodyBold>
            <STrapezoidBottomBox />
          </>
        )}
      </VFlex>
    );
  },
);

const DailyRewardDialog = observer((props: DailyRewardDialogProps) => {
  return (
    <BaseDialogUI
      dialogID={ID}
      storeGetter={() => {
        return store.isShowDailyRewardDialog;
        // return true;
      }}
      onShow={() => {}}
      onClose={() => {
        store.isShowDailyRewardDialog = false;
      }}
      style={{
        padding: "8px 16px",
        gap: 8,
      }}
      containerStyle={{ overflowY: "scroll" }}
    >
      {(() => {
        if (!store.isShowDailyRewardDialog) return;
        const n = now();
        return (
          <>
            <Flex className="bg-[#1e3546] h-[58px] items-center justify-center rounded-lg self-stretch">
              <img
                style={{ height: 48, width: 48 }}
                src="/img/daily-reward-icon.svg"
              />
              <S18BodyBold className="translate-y-[2px]">
                Daily Reward
              </S18BodyBold>
            </Flex>
            <SBody
              style={{
                fontSize: 14,
                textAlign: "center",
              }}
            >
              Earn a daily energy bonus, simply check-in everyday. Keep the
              streak by not missing a day.
            </SBody>
            <VFlex className="flex-1 items-stretch self-stretch overflow-y-scroll">
              <div
                style={{}}
                className="grid grid-cols-4 gap-x-2 gap-y-2 w-full"
              >
                {store.gameConfig?.daily_reward_table.map((n, idx) => {
                  const day = idx + 1;
                  return (
                    <DayComponent
                      key={idx}
                      day={day}
                      isChecked={
                        day <=
                        (authStore.character?.continuous_checkin_days ?? 0)
                      }
                      isHighlighted={
                        day === authStore.character?.continuous_checkin_days
                      }
                      item={n}
                    />
                  );
                })}
              </div>
            </VFlex>
            <MainButton
              style={{
                alignSelf: "stretch",
                marginTop: 12,
              }}
              onClick={async () => {
                store.isShowLoadingDialog = true;
                try {
                  const res = await claimDailyReward();
                  authStore.character = res.character;
                  store.isShowLoadingDialog = false;
                  store.isShowDailyRewardDialog = false;
                  store.claimedDialogState = {
                    rewards: res.rewards,
                  };
                } catch (error) {
                  store.showErrorDialog(error);
                  store.isShowLoadingDialog = false;
                }
              }}
              disabled={
                n <
                (authStore.character?.next_daily_reward_available_at ?? 0) *
                  1000
              }
            >
              Claim
            </MainButton>
            <MainButton
              style={{
                background: "transparent",
                color: "white",
                fontWeight: 400,
              }}
              onClick={() => {
                store.isShowDailyRewardDialog = false;
              }}
            >
              Done
            </MainButton>
          </>
        );
      })()}
    </BaseDialogUI>
  );
});

export default DailyRewardDialog;
