import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import Lottie from "react-lottie";
import { M_MS, REWARD_DATA, S_MS } from "../../constants";
import { store } from "../../store/store";
import { MainBorderButtonV2, MainButton } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import {
  S14Body,
  S14Body600,
  S18BodyBold,
  S20Body600,
  S24Body600,
  SBodyBold,
} from "../Typography";
import BaseDialogUI from "./BaseDialogUI";

import { now } from "mobx-utils";
import { claimPassiveReward } from "../../api/character";
import animationData from "../../lottie/breath-guide.json";
import { authStore } from "../../store/auth-store";
import { getTimeLeftSimpleStr, numberInGroups } from "../../utils";
import ClaimedLottie from "../ClaimedLottie";
import HeartSVG from "../SVG/HeartSVG";
import TimerSVG from "../SVG/TimerSVG";

interface ReflectDialogProps {
  style?: CSSProperties;
}

const ID = "ReflectDialog";

const ReflectDialog = observer((props: ReflectDialogProps) => {
  return (
    <BaseDialogUI
      dialogID={ID}
      storeGetter={() => {
        return store.reflectDialogState;
        // return true;
      }}
      onShow={() => {}}
      onClose={() => {
        store.reflectDialogState = null;
      }}
      style={{
        padding: "8px 14px",
      }}
      containerStyle={{ flex: 1, gap: 16, overflow: "hidden" }}
    >
      {(() => {
        if (!store.reflectDialogState) return null;
        return (
          <>
            <VFlex className="rounded-xl self-stretch h-[48px] bg-[#0C1C2760] items-center justify-center">
              <S18BodyBold>Reflect</S18BodyBold>
            </VFlex>
            {(() => {
              if (store.reflectDialogState?.currentState === "done") {
                return (
                  <>
                    <S14Body className="text-center">
                      Congratulations, you just finished your reflect session.
                    </S14Body>
                    <SBodyBold className="text-center">
                      Here is a reward for you:
                    </SBodyBold>
                    <VFlex
                      className="h-[136px] self-stretch bg-[#12222E] rounded-2xl items-center justify-center"
                      style={{
                        border: "1px solid #376F9E",
                      }}
                    >
                      <img
                        className="w-[73px] h-[73px]"
                        src={REWARD_DATA.energy.icon}
                      />
                      <S20Body600>
                        {`+${store.reflectDialogState.increasedEnergy} ENERGY + EXP`}
                      </S20Body600>
                    </VFlex>
                    <Flex className="gap-2">
                      <HeartSVG />
                      <S14Body600>Wellness tip:</S14Body600>
                    </Flex>
                    <S14Body className="text-center flex-1">
                      Breathing exercises can reduce heart rate and lower blood
                      pressure. They also improve delivery of oxygen and
                      nutrients to your muscles during and after training.
                    </S14Body>
                    <MainBorderButtonV2
                      className="self-stretch"
                      onClick={() => {
                        store.reflectDialogState = null;
                      }}
                    >
                      Done
                    </MainBorderButtonV2>
                    <ClaimedLottie />
                  </>
                );
              }
              if (store.reflectDialogState?.currentState === "reflecting") {
                const n = now();
                const timeLeft =
                  (store.reflectDialogState?.startingTime ?? 0) + 30000 - n;
                if (timeLeft < 0) {
                  requestAnimationFrame(async () => {
                    if (!store.reflectDialogState || store.isShowLoadingDialog)
                      return;
                    store.isShowLoadingDialog = true;
                    const character = await claimPassiveReward();
                    const increasedEnergy =
                      character.energy - (authStore.character?.energy ?? 0);
                    store.reflectDialogState.increasedEnergy = increasedEnergy;
                    store.reflectDialogState.currentState = "done";
                    authStore.character = character;
                    store.isShowLoadingDialog = false;
                  });
                }
                return (
                  <>
                    <S14Body className="text-center">
                      Follow the guide, inhale and exhale. Sit up straight in a
                      chair or lie down in a comfortable position. Keep your
                      shoulders pulled back and away from your ears.
                    </S14Body>
                    <Lottie
                      style={{
                        pointerEvents: "none",
                        zIndex: 5,
                        marginTop: -100,
                        opacity: 0.8,
                        transform: "scale(1)",
                      }}
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                      }}
                    />
                    <Flex className="items-center gap-2 absolute bottom-10">
                      <TimerSVG />
                      <S24Body600>
                        {"00:" +
                          (getTimeLeftSimpleStr(timeLeft)
                            .split(":")
                            .splice(-1, 1)[0] || "00")}
                      </S24Body600>
                    </Flex>
                  </>
                );
              }
              const n = now();
              const claimedPassiveMS =
                (authStore.character?.last_passive_reward_claim ?? 0) * 1000;
              const passiveReadyMS = claimedPassiveMS + 3 * M_MS;
              const passiveFillPercent = Math.min(
                1,
                (n - claimedPassiveMS) /
                  (S_MS *
                    (store.gameConfig?.passive_claim_max_seconds ?? 10800)),
              );
              const energy =
                passiveFillPercent *
                (authStore.character?.energy_per_hour ?? 0) *
                3;
              return (
                <>
                  <VFlex
                    className="rounded-lg overflow-hidden"
                    style={{
                      border: "1px solid #3a81c9",
                      background:
                        "linear-gradient(180deg, #1e2f3e 0%, #49b6cf 100%)",
                    }}
                  >
                    <img
                      className="self-stretch"
                      src="/img/reflect-banner.png"
                    />
                  </VFlex>
                  <S14Body className="text-center">
                    Take a moment to relax and reflect, it's important to have
                    peace of mind and you can earn points by doing so.
                  </S14Body>
                  <Flex
                    className="rounded-lg overflow-hidden items-center justify-center h-[58px] self-stretch gap-2"
                    style={{
                      border: "1px solid #3a81c9",
                    }}
                  >
                    <img
                      className="w-[42px] h-[42px]"
                      src={REWARD_DATA.energy.icon}
                    />
                    <VFlex>
                      <S14Body600>Reward</S14Body600>
                      <S14Body600>{`~${numberInGroups(energy.toFixed(0))} Energy + EXP`}</S14Body600>
                    </VFlex>
                  </Flex>
                  <MainButton
                    className="self-stretch"
                    large
                    onClick={() => {
                      runInAction(() => {
                        if (!store.reflectDialogState) return;
                        store.reflectDialogState.currentState = "reflecting";
                        store.reflectDialogState.startingTime = Date.now();
                      });
                    }}
                  >
                    Start
                  </MainButton>
                </>
              );
            })()}
          </>
        );
      })()}
    </BaseDialogUI>
  );
});

export default ReflectDialog;
