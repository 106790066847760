import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import styled from "styled-components";
import { MAGIC_CRATE_DATA, REWARD_DATA } from "../../constants";
import { store } from "../../store/store";
import { EMagicCrateTypes, ERewardTypes } from "../../types/types";
import { MainBorderButtonV2, MainButton } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import SolidCircleQuestionMarkSVG from "../SVG/SolidCircleQuestionMarkSVG";
import { S14Body600, S18Body600, SBody, SBody600 } from "../Typography";
import BaseDialogUI from "./BaseDialogUI";
interface MagicCratesDialogProps {
  style?: CSSProperties;
}

const ID = "MagicCratesDialog";

const SBoxItemContainer = styled(Flex)`
  background: #ffffff10 0% 0% no-repeat padding-box;
  border: 1px solid #82b3d6;
  border-radius: 9px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  align-self: stretch;
  height: 86px;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;
`;

const SBoxPriceContainer = styled(Flex)`
  background: #172937 0% 0% no-repeat padding-box;
  border: 1px solid #5ea5d6;
  border-radius: 8px;
  opacity: 1;
  height: 40px;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const MagicCratesDialog = observer((props: MagicCratesDialogProps) => {
  return (
    <BaseDialogUI
      dialogID={ID}
      storeGetter={() => {
        return store.magicCratesDialogState;
        // return true;
      }}
      onShow={() => {}}
      onClose={() => {
        store.magicCratesDialogState = null;
      }}
      style={{
        padding: "8px 16px",
        gap: 8,
      }}
      containerStyle={{ flex: 1 }}
    >
      {(() => {
        if (!store.magicCratesDialogState) return null;
        if (store.magicCratesDialogState.isShowInfo) {
          return (
            <>
              <VFlex
                style={{
                  backgroundColor: "#1c3445",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderRadius: 12,
                  overflow: "hidden",
                }}
              >
                <Flex
                  style={{
                    alignItems: "center",
                    height: 58,
                    gap: 6,
                  }}
                >
                  <img src="/img/magic-crate-icon.svg" />
                  <SBody style={{ fontWeight: "bold" }}>Magic Crates</SBody>
                </Flex>
              </VFlex>
              <VFlex className="self-stretch items-start gap-3 flex-1 overflow-y-scroll">
                <S18Body600>how it works</S18Body600>
                <SBody>
                  You can buy Magic Crates using $WELL from 3 daily different
                  crates. Each crate holds one of the following prizes: $WELL,
                  $TON or Energy.
                </SBody>
                <SBody>
                  Every 24 hours, the current round will end and the contents of
                  each Magic Crate will be revealed. The prize inside each of
                  the crates will be split equally among all the buyers based on
                  the total number of purchases for the same crate.
                </SBody>
                <SBody>
                  Example: If Magic Crate #1 holds 10 $TON and all players
                  bought a total of 100 Magic Crates, each buyer of Magic Crate
                  #1 gets 0.1 $TON.
                </SBody>
              </VFlex>
              <MainBorderButtonV2
                className="self-stretch"
                onClick={() => {
                  if (!store.magicCratesDialogState) return;
                  store.magicCratesDialogState.isShowInfo = false;
                }}
              >
                Done
              </MainBorderButtonV2>
            </>
          );
        }
        return (
          <>
            <VFlex
              style={{
                backgroundColor: "#1c3445",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "stretch",
                borderRadius: 12,
                overflow: "hidden",
              }}
            >
              <Flex
                style={{
                  alignItems: "center",
                  height: 58,
                  gap: 6,
                }}
              >
                <img src="/img/magic-crate-icon.svg" />
                <SBody style={{ fontWeight: "bold" }}>Magic Crates</SBody>
              </Flex>
              <Flex
                style={{
                  backgroundColor: "#0C1C27",
                  alignSelf: "stretch",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 28,
                  color: "#80B1D4",
                }}
              >
                <S18Body600>Round 18</S18Body600>
              </Flex>
            </VFlex>
            <VFlex
              style={{
                backgroundColor: "#1c3445",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "stretch",
                borderRadius: 12,
                overflow: "hidden",
              }}
            >
              <Flex
                style={{
                  justifyContent: "space-between",
                  alignSelf: "stretch",
                  alignItems: "center",
                  height: 32,
                  gap: 6,
                  backgroundColor: "#1B3243",
                }}
              >
                <SBody600 className="pl-4">Rewards:</SBody600>
                <Flex
                  style={{
                    alignSelf: "stretch",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 74,
                    background:
                      " linear-gradient(180deg, #375974 0%, #5583A7 100%)",
                    gap: 6,
                  }}
                  onClick={() => {
                    if (!store.magicCratesDialogState) return;
                    store.magicCratesDialogState.isShowInfo = true;
                  }}
                >
                  <SBody600>Info</SBody600>
                  <SolidCircleQuestionMarkSVG />
                </Flex>
              </Flex>
              <Flex
                style={{
                  backgroundColor: "#0C1C27",
                  alignSelf: "stretch",
                  alignItems: "center",
                  height: 47,
                  color: "##172937",
                }}
              >
                {Object.entries(REWARD_DATA).map(([k, v], idx, arr) => {
                  const isLast = arr.length - 1 === idx;
                  return (
                    <Flex
                      key={k}
                      className="flex-1 justify-center items-center gap-2"
                      style={{
                        borderRight: isLast ? "none" : "1px solid #385B74",
                      }}
                    >
                      <img className="w-7 h-7" src={v.icon} />
                      <SBody600>{v.title}</SBody600>
                    </Flex>
                  );
                })}
              </Flex>
            </VFlex>
            <Flex className="self-stretch justify-between">
              <SBody>Round ends in:</SBody>
              <Flex>
                <SBody600>16:30:04</SBody600>
              </Flex>
            </Flex>
            {Object.entries(MAGIC_CRATE_DATA).map(([k, v], idx, arr) => {
              return (
                <SBoxItemContainer>
                  <img
                    style={{
                      width: 97,
                    }}
                    src={v.icon}
                  />
                  <SBody600 className="flex-1">{v.title}</SBody600>
                  <VFlex className="w-[148px] items-stretch">
                    <Flex className="justify-between">
                      <SBody>Owned</SBody>
                      <SBody600>x 0</SBody600>
                    </Flex>
                    <SBoxPriceContainer
                      className="simple-btn"
                      onClick={() => {
                        store.openBuyMagicCratesDialog(
                          k as unknown as EMagicCrateTypes,
                        );
                      }}
                    >
                      <S14Body600>1.5K</S14Body600>
                      <img
                        className="w-7 h-7"
                        src={REWARD_DATA[ERewardTypes.WELL].icon}
                      />
                      <S14Body600>$WELL</S14Body600>
                    </SBoxPriceContainer>
                  </VFlex>
                </SBoxItemContainer>
              );
            })}
            <MainButton
              className="self-stretch"
              onClick={() => {
                store.openMagicCratesResultDialog();
              }}
            >
              Previous Results
            </MainButton>
          </>
        );
      })()}
    </BaseDialogUI>
  );
});

export default MagicCratesDialog;
