import { observer } from "mobx-react-lite";
import moment from "moment";
import { CSSProperties } from "react";

import { store } from "../../store/store";
import { Withdrawal } from "../../types/api-types";
import { numberInGroups } from "../../utils";
import BaseTable from "../BaseTable";
import { MainBorderButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import HistorySVG from "../SVG/HistorySVG";
import { S18BodyBold, SBody, SBodyBold } from "../Typography";
import BaseDialogUI from "./BaseDialogUI";
interface WithdrawalHistoryDialogProps {
  style?: CSSProperties;
}

const ID = "WithdrawalHistoryDialog";

const COL_STYLES = {};

const WithdrawalHistoryDialog = observer(
  (props: WithdrawalHistoryDialogProps) => {
    return (
      <BaseDialogUI
        dialogID={ID}
        storeGetter={() => {
          return store.isShowWithdrawalHistoryDialog;
          // return true;
        }}
        onShow={() => {}}
        onClose={() => {
          store.isShowWithdrawalHistoryDialog = null;
        }}
        style={{
          padding: "8px 14px",
        }}
        containerStyle={{ flex: 1, gap: 16, overflow: "hidden" }}
      >
        {(() => {
          if (!store.isShowWithdrawalHistoryDialog) return null;
          return (
            <>
              <VFlex className="flex-1 self-stretch overflow-hidden">
                <Flex
                  className="items-center bg-[#1b3040] self-stretch justify-center"
                  style={{
                    borderRadius: "12px 12px 0px 0px",
                  }}
                >
                  <HistorySVG />
                  <S18BodyBold>Withdrawls History</S18BodyBold>
                </Flex>
                <VFlex
                  className="flex-1 self-stretch overflow-hidden"
                  style={{
                    border: "1px solid #709cbb",
                  }}
                >
                  <BaseTable
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    keyGetter={(data: any) => data.id}
                    colConfig={{
                      id: {
                        title: "ID",
                        stylesThTr: { minWidth: 70 },
                        render: (data: any, configKey, configValue) => {
                          return <SBody>{data.id}</SBody>;
                        },
                      },
                      currency: {
                        title: "Currency",
                        stylesThTr: { minWidth: 120 },
                        render: (data: any, configKey, configValue) => {
                          return <SBody>{data.currency}</SBody>;
                        },
                      },
                      amount: {
                        title: "Amount",
                        stylesThTr: { minWidth: 120 },
                        render: (data: any, configKey, configValue) => {
                          return <SBody>{numberInGroups(data.amount)}</SBody>;
                        },
                      },
                      requestDate: {
                        title: "Request Date",
                        stylesThTr: { minWidth: 240 },
                        render: (data: Withdrawal, configKey, configValue) => {
                          return (
                            <SBody>
                              {moment(data.signed_at * 1000).format(
                                "YYYY-MM-DD",
                              )}
                            </SBody>
                          );
                        },
                      },
                      sentDate: {
                        title: "Sent Date",
                        stylesThTr: { minWidth: 240 },
                        render: (data: Withdrawal, configKey, configValue) => {
                          return (
                            <SBody>
                              {moment(data.signed_at * 1000).format(
                                "YYYY-MM-DD",
                              )}
                            </SBody>
                          );
                        },
                      },
                    }}
                    data={store.isShowWithdrawalHistoryDialog.withdrawals || []}
                    emptyContent={<SBodyBold>No withdrawl history</SBodyBold>}
                    loading={!store.isShowWithdrawalHistoryDialog.withdrawals}
                  />
                </VFlex>
              </VFlex>
              <MainBorderButtonV2
                onClick={() => {
                  store.isShowWithdrawalHistoryDialog = null;
                }}
              >
                Go back
              </MainBorderButtonV2>
            </>
          );
        })()}
      </BaseDialogUI>
    );
  },
);

export default WithdrawalHistoryDialog;
