import styled, { FastOmit } from "styled-components";
import { IStyledComponentBase } from "styled-components/dist/types";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  centerColor?: string;
  outerColor?: string;
}

type TMerged = IStyledComponentBase<
  "web",
  FastOmit<React.DetailedHTMLProps<IProps, HTMLDivElement>, never>
>;

export const SCircularGradiantBox: TMerged = styled.div`
  border-radius: 10000px;
  width: 100%;
  aspect-ratio: 1 / 1;
  background: radial-gradient(
    50% 50% at 50% 50%,
    ${(props: any) => props.centerColor || "#265e83f0"} 0%,
    ${(props: any) => props.outerColor || "#00000000"} 100%
  );
  position: absolute;
  pointer-events: none;
`;
