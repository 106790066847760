import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import { REWARD_DATA } from "../constants";
import { authStore } from "../store/auth-store";
import { store } from "../store/store";
import { ERewardTypes } from "../types/types";
import { numberInGroups } from "../utils";
import { MainBorderButtonV2 } from "./MainButton";
import { Flex, VFlex } from "./styled/Flex";
import StylishContainer from "./StylishContainer";
import TabGroup from "./TabGroup";
import {
  S12Body,
  S14Body,
  S14BodyBold,
  S15BodyBold,
  S18BodyBold,
  SBody600,
  SBodyBold,
} from "./Typography";

interface LuckyPickDialogInfoPartProps
  extends React.HTMLAttributes<HTMLDivElement> {
  style?: CSSProperties;
  banner?: any;
}

const LuckyPickDialogInfoPart = observer(
  (props: LuckyPickDialogInfoPartProps) => {
    const { banner } = props;
    if (store.luckyPickDialogState === false) return null;
    if (typeof store.luckyPickDialogState?.state === "object") {
      return null;
    }
    if (typeof store.luckyPickDialogState?.isShowInfo !== "string") return null;
    return (
      <>
        <TabGroup
          tabs={[
            {
              key: "jackpot",
              label: "Magic jackpot",
            },
            {
              key: "how",
              label: "How to play",
            },
            {
              key: "rewards",
              label: "Rewards",
            },
          ]}
          onChangeTab={(t) => {
            store.luckyPickDialogState &&
              (store.luckyPickDialogState.isShowInfo = t.key);
          }}
          contentStyle={{ fontSize: 11 }}
          defaultTabKey={store.luckyPickDialogState?.isShowInfo || "jackpot"}
        />
        {(() => {
          if (store.luckyPickDialogState?.isShowInfo === "jackpot") {
            return (
              <>
                {banner}
                <S18BodyBold>Magic Jackpot</S18BodyBold>
                <S14Body
                  style={{
                    textAlign: "center",
                  }}
                >
                  Play with $WELL or $TON for a chance to win one of the Magic
                  Jackpots! Every time someone plays, the prize grows, and the
                  winner takes the whole Magic Jackpot Prize!
                </S14Body>
                <Flex className="flex-1 gap-3">
                  {[
                    {
                      icon: REWARD_DATA.ton.icon,
                      v: store.luckyPickPools?.ton_pool.balance ?? 0,
                      name: "$TON",
                    },
                    {
                      icon: REWARD_DATA.well.icon,
                      v: store.luckyPickPools?.well_pool.balance ?? 0,
                      name: "$WELL",
                    },
                  ].map((r, i) => (
                    <VFlex>
                      <Flex className="gap-2">
                        <img
                          style={{ width: 54, height: 54 }}
                          src="/img/jackpot-pot-icon.png"
                        />
                        <VFlex>
                          <Flex className="items-center gap-1">
                            <img className="w-[18px] h-[18px]" src={r.icon} />
                            <SBodyBold>{r.name}</SBodyBold>
                          </Flex>
                          <S12Body>Magic Jackpot</S12Body>
                        </VFlex>
                      </Flex>
                      <S12Body>Price:</S12Body>
                      <S15BodyBold className="text-[#FFBB40]">
                        {`${numberInGroups(r.v)} ${r.name}`}
                      </S15BodyBold>
                    </VFlex>
                  ))}
                </Flex>
              </>
            );
          }
          if (store.luckyPickDialogState?.isShowInfo === "how") {
            return (
              <>
                <img className="w-full" src="/img/lucky-pick-how-to.png" />
                <S18BodyBold>How to play Lucky Pick</S18BodyBold>
                <S14Body
                  style={{
                    textAlign: "center",
                  }}
                >
                  The game consists of 12 cards. You simply pick a card to
                  receive the prize it holds.
                </S14Body>
                <Flex className="gap-2">
                  {Object.entries(REWARD_DATA).map(([k, v]) => {
                    return (
                      <StylishContainer
                        key={k}
                        outerComponent={
                          <SBody600 className="text-center">{v.title}</SBody600>
                        }
                      >
                        <img className="w-10 h-10" src={v.icon} />
                      </StylishContainer>
                    );
                  })}
                </Flex>
              </>
            );
          }
          const table = (() => {
            if (store.luckyPickDialogState?.showingRewardType === "ton") {
              return store.gameConfig?.lucky_pick_table?.ton_rewards;
            }
            if (store.luckyPickDialogState?.showingRewardType === "well") {
              return store.gameConfig?.lucky_pick_table?.well_rewards;
            }
            return store.gameConfig?.lucky_pick_table?.energy_rewards;
          })();
          return (
            <>
              <S18BodyBold>Rewards list</S18BodyBold>
              <TabGroup
                tabs={Object.entries(REWARD_DATA).map(([k, v]) => {
                  return { key: k, label: v.title };
                })}
                onChangeTab={(t) => {
                  store.luckyPickDialogState &&
                    (store.luckyPickDialogState.showingRewardType = t.key);
                }}
                contentStyle={{ fontSize: 11 }}
                defaultTabKey={store.luckyPickDialogState?.showingRewardType}
              />
              <VFlex
                style={{
                  flex: 1,
                  borderRadius: 12,
                  backgroundColor: "#0C1C27",
                  alignSelf: "stretch",
                  alignItems: "stretch",
                  overflowY: "scroll",
                  padding: 10,
                  gap: 8,
                }}
              >
                <div style={{ height: "100px" }}>
                  {[
                    {
                      title: "Magic Jackpot Reward",
                      background:
                        "linear-gradient(264deg, #F9EF8D 0%, #EFC463 36%, #BA7030 66%, #9C522D 100%)",
                      icon: "/img/lucky-jackpot-icon.png",
                      name: "",
                      items: [
                        `${numberInGroups(
                          store.luckyPickPools?.ton_pool.balance ?? 0,
                        )} $TON`,
                        `${numberInGroups(
                          store.luckyPickPools?.well_pool.balance ?? 0,
                        )} $WELL`,
                      ],
                    },
                    {
                      title: "$WELL Rewards",
                      background: REWARD_DATA.well.themeColor,
                      icon: REWARD_DATA[ERewardTypes.WELL].icon,
                      name: "$WELL",
                      items: table
                        ?.filter((v) => v.type === ERewardTypes.WELL)
                        .map((v) => numberInGroups(v.amount, 3)),
                    },
                    {
                      title: "$TON Rewards",
                      background: REWARD_DATA.ton.themeColor,
                      icon: REWARD_DATA[ERewardTypes.TON].icon,
                      name: "$TON",
                      items: table
                        ?.filter((v) => v.type === ERewardTypes.TON)
                        .map((v) => numberInGroups(v.amount, 3)),
                    },
                    {
                      title: "Energy Rewards",
                      background: REWARD_DATA.energy.themeColor,
                      icon: REWARD_DATA[ERewardTypes.Energy].icon,
                      name: "Energy",
                      items: table
                        ?.filter((v) => v.type === ERewardTypes.Energy)
                        .map((v) =>
                          numberInGroups(
                            v.multiplier *
                              (authStore.character?.lucky_pick_energy_cost ??
                                0),
                            3,
                          ),
                        ),
                    },
                  ].map(({ title, background, items, icon, name }, idx) => {
                    return (
                      <VFlex className="gap-2" key={title}>
                        <Flex
                          style={{
                            background,
                            height: 38,
                            paddingLeft: 8,
                            alignItems: "center",
                            borderRadius: 8,
                          }}
                        >
                          <SBodyBold>{title}</SBodyBold>
                        </Flex>
                        <div className="gap-x-1 gap-y-1 grid grid-cols-1">
                          {items?.map((v, i) => {
                            return (
                              <Flex
                                style={{ gap: 7 }}
                                key={v}
                                className="bg-[#081117] w-full p-2 rounded-lg"
                              >
                                <img className="w-5 h-5" src={icon} />
                                <S14BodyBold>{v}</S14BodyBold>
                                <S14Body>{name}</S14Body>
                              </Flex>
                            );
                          })}
                        </div>
                      </VFlex>
                    );
                  })}
                </div>
              </VFlex>
            </>
          );
        })()}
        <MainBorderButtonV2
          style={{ alignSelf: "stretch" }}
          onClick={() => {
            if (store.luckyPickDialogState) {
              store.luckyPickDialogState.isShowInfo = undefined;
            }
          }}
        >
          Done
        </MainBorderButtonV2>
      </>
    );
  },
);

export default LuckyPickDialogInfoPart;
