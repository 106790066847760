import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import { Flex } from "./styled/Flex";
interface ProgressBarProps {
  style?: CSSProperties;
  percent: number;
}

const ProgressBar = observer((props: ProgressBarProps) => {
  const { style, percent } = props;
  return (
    <Flex
      style={{
        height: 16,
        border: "0.5px solid #FFFFFF",
        borderRadius: 100,
        alignItems: "stretch",
        padding: 3,
        ...style,
      }}
    >
      <Flex
        style={{
          borderRadius: 12,
          backgroundColor: "#4a5760",
          flex: 1,
        }}
      >
        <Flex
          style={{
            borderRadius: 12,
            backgroundColor: "#54DAF3",
            width: `${percent}%`,
            boxShadow: "0px 0px 20px 2px #54DAF3",
          }}
        ></Flex>
      </Flex>
    </Flex>
  );
});

export default ProgressBar;
