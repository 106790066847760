import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import Lottie from "react-lottie";

import { store } from "../../store/store";
import { MainButtonV2 } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import { S20Body, S20BodyBold, SBody } from "../Typography";
import BaseDialog from "./BaseDialog";

import _ from "lodash";
import animationData from "../../lottie/star-burst-v2.json";

interface HatchedDialogProps {
  style?: CSSProperties;
}

const ID = "HatchedDialog";

const HatchedDialog = observer((props: HatchedDialogProps) => {
  return (
    <BaseDialog
      dialogID={ID}
      storeGetter={() => {
        return store.hatchedDialogState;
      }}
      onShow={() => {}}
      onClose={() => {
        store.hatchedDialogState = null;
      }}
      style={{
        gap: 8,
        transition: "all 0.3s ease",
        opacity: store.hatchedDialogState ? 1 : 0,
      }}
    >
      <VFlex
        style={{
          position: "relative",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <VFlex
          style={{
            border: "1px solid #376F9E",
            borderRadius: 16,
            overflow: "hidden",
            margin: "16px",
            // height: 300,
            backgroundColor: "#191e22",
          }}
        >
          <Flex className="self-stretch items-center justify-center h-[58px] bg-[#0C1C27]">
            <S20BodyBold>Hatched!</S20BodyBold>
          </Flex>
          <VFlex
            className="self-stretch items-center justify-center flex-1 p-6"
            style={{
              background: "#FFFFFF10",
            }}
          >
            <img
              style={{ height: 256, width: 256, borderRadius: 24 }}
              src={`/img/skills/${store.hatchedDialogState?.hatched_item?.item_id}.jpg?123`}
            />
            <Flex>
              {/* <S20BodyBold>{`+${store.hatchedDialogState?.}`}</S20BodyBold> */}
              <S20Body>
                &nbsp;
                {
                  store.gameConfig?.item_templates.find(
                    (t) =>
                      t.id ===
                      (store.hatchedDialogState?.hatched_item?.item_id || 0) +
                        "",
                  )?.item_name
                }
              </S20Body>
            </Flex>
            <SBody
            // style={{color: }}
            >
              {_.upperFirst(store.hatchedDialogState?.hatched_item?.rarity)}
            </SBody>
          </VFlex>
        </VFlex>
        <MainButtonV2
          style={{
            margin: "0px 16px",
          }}
          onClick={() => {
            store.hatchedDialogState = null;
          }}
          large
        >
          Done
        </MainButtonV2>
        <Lottie
          style={{
            position: "absolute",
            pointerEvents: "none",
            zIndex: 5,
          }}
          key={JSON.stringify(store.hatchedDialogState)}
          height={store.screenSize.width * 0.8}
          width={store.screenSize.width * 0.8}
          options={{
            loop: false,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
        />
      </VFlex>
    </BaseDialog>
  );
});

export default HatchedDialog;
