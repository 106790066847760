import { observer } from "mobx-react-lite";
import { now } from "mobx-utils";
import moment from "moment";
import { CSSProperties, useRef } from "react";
import Lottie from "react-lottie";
import styled from "styled-components";

import { H_MS, M_MS } from "../../constants";
import animationData from "../../lottie/charging.json";
import { authStore } from "../../store/auth-store";
import { store } from "../../store/store";
import { numberInGroups, useSize } from "../../utils";
import GradiantButton from "../GradiantButton";
import Header from "../Header";
import MobxCountdownTimer from "../MobxCountdownTimer";
import ProgressBar from "../ProgressBar";
import { SCircularGradiantBox } from "../SCircularGradiantBox";
import { Flex, VFlex } from "../styled/Flex";
import LeaderboardSVG from "../SVG/LeaderboardSVG";
import {
  S11Body,
  S12Body,
  S12BodyLighter,
  S18Body600,
  SBody,
  SBody600,
} from "../Typography";
interface MainTabPageProps {
  style?: CSSProperties;
}

const STrapezoidBottomBox = styled(Flex)`
  width: 168px;
  height: 62px;
  box-shadow: 0px -3px 12px #54a8f380;
  border: 2px solid #000000;
  position: absolute;
  background-color: black;
  transform: ${(props) =>
    `perspective(10px) rotateX(2deg) ${props.style?.transform ?? ""}`};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RewardButton = observer(
  (props: {
    title: string;
    onClick: () => void;
    nextAvailableTS: number;
    claimedTS: number;
    imgComponent: any;
    text?: string;
  }) => {
    const { text, title, imgComponent, onClick, nextAvailableTS, claimedTS } =
      props;
    const n = now() / 1000;
    return (
      <GradiantButton
        style={{ flex: 1 }}
        // onClick={claimedTS > t0ts ? () => {} : onClick}
        onClick={onClick}
      >
        <VFlex style={{ gap: 3 }}>
          <Flex style={{ alignItems: "center" }}>
            {imgComponent}
            <SBody style={{ fontSize: 12 }}>{title}</SBody>
          </Flex>
          <Flex style={{ gap: 6 }}>
            {nextAvailableTS < n ? (
              <SBody
                style={{
                  fontSize: 12,
                  fontWeight: "lighter",
                  textAlign: "center",
                  flex: 1,
                }}
              >
                {text || "Claim Now!"}
              </SBody>
            ) : (
              <>
                <ProgressBar
                  style={{ flex: 1 }}
                  percent={
                    (1 -
                      ((nextAvailableTS - claimedTS) * 1000) /
                        (24 * 60 * 60 * 1000)) *
                    100
                  }
                />
                <MobxCountdownTimer
                  style={{ width: 45 }}
                  Wrapper={S12Body}
                  deadline={nextAvailableTS * 1000}
                />
              </>
            )}
            {/* <SBody style={{ fontSize: 12, fontWeight: "lighter" }}>
              {timeText}
            </SBody> */}
          </Flex>
        </VFlex>
      </GradiantButton>
    );
  },
);
{
  /* <img
                style={{ height: 48, width: 48, marginLeft: -6 }}
                src="/img/orb.png"
              /> */
}
const MainTabPage = observer((props: MainTabPageProps) => {
  const n = now();
  const nextDay0TS = moment(n)
    .add(1, "day")
    .set({ hour: 0, minute: 0, second: 0 })
    .unix();
  const today0TS = moment(n).set({ hour: 0, minute: 0, second: 0 }).unix();
  // const handleAddPointClick = useCallback((evt) => {
  //   const cb = (obj) => {
  //     const id = Math.random();
  //     store.clickTextDict[id] = {
  //       value: 5,
  //       position: {
  //         left: obj.clientX,
  //         top: obj.clientY,
  //       },
  //     };

  //     store.points += 5;
  //     tabStore.addEvent(5, { left: evt.clientX, top: evt.clientY });
  //   };
  //   Object.values(evt.touches).forEach(cb);
  // }, []);
  const clickerDivRef = useRef();
  const clickerDivSize = useSize(clickerDivRef);
  const claimedPassiveMS =
    (authStore.character?.last_passive_reward_claim ?? 0) * 1000;
  const passiveReadyMS = claimedPassiveMS + 3 * M_MS;
  const isReflectReady = n > passiveReadyMS;
  const passiveFillPercent = (n - claimedPassiveMS) / (3 * H_MS);
  const isClickerDivShort =
    (clickerDivSize?.width || 0) > (clickerDivSize?.height || 0);

  const earnPerHour = `${numberInGroups(authStore.earnPerHour)}`;
  // const earnPerHour = shortenNumberString(authStore.earnPerHour ?? 0, 0);
  const lrTranslate = (() => {
    if (store.screenSize.width < 392) {
      return -68;
    }
    return -58;
    // if (store.screenSize.width < 551) {

    // }
  })();
  return (
    <VFlex
      style={{
        flex: 1,
        alignItems: "stretch",
        marginBottom: -20,
        overflow: "hidden",
      }}
    >
      {/* Top Part */}
      <Header />
      <Flex
        style={{
          marginTop: 20,
        }}
        className="mb-2"
      >
        <Flex
          style={{
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
            borderRight: "1px solid #1D2E3B",
          }}
        >
          <SBody style={{ fontSize: "19px" }}>LVL</SBody>
          <VFlex className="items-center justify-center">
            <img
              style={{ width: 50, height: 50, transform: "translateY(-2px)" }}
              src="/img/lvl-1.png"
            />
            <SBody600 className="absolute" style={{}}>
              {authStore.expData?.level ?? 0}
            </SBody600>
          </VFlex>
        </Flex>
        <Flex
          style={{
            alignItems: "center",
            flex: 1.3,
            justifyContent: "center",
            borderRight: "1px solid #1D2E3B",
          }}
        >
          <VFlex style={{ flex: 1, alignItems: "stretch" }}>
            <Flex style={{ justifyContent: "center" }}>
              <SBody style={{ whiteSpace: "none" }}>
                <span style={{ fontWeight: "bold" }}>XP</span>&nbsp;
                {numberInGroups(authStore.currentExp)}
                &nbsp;/&nbsp;
                <span
                  style={{
                    color: "#54DAF3",
                  }}
                >
                  {authStore.nextExpRequired
                    ? numberInGroups(authStore.nextExpRequired)
                    : "--"}
                </span>
              </SBody>
            </Flex>
            <ProgressBar
              percent={(authStore.currentExp * 100) / authStore.nextExpRequired}
              style={{ margin: "3px 12px 0px 12px" }}
            />
          </VFlex>
        </Flex>
        <Flex
          style={{
            alignItems: "stretch",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <GradiantButton
            style={{
              alignSelf: "stretch",
              flex: 1,
              maxWidth: 88,
            }}
            className="relative"
            c1="#757a7c"
            c2="#d8d8d8"
          >
            <VFlex style={{ alignItems: "center" }}>
              <LeaderboardSVG />
              <span style={{ fontSize: 11 }}>Leaderboard</span>
            </VFlex>
            <div className="absolute w-full bg-[#163A54] text-center">
              <S11Body>Coming Soon!</S11Body>
            </div>
          </GradiantButton>
        </Flex>
      </Flex>
      <Flex style={{ gap: 11, padding: "4px 16px" }}>
        <RewardButton
          title="Daily Reward"
          nextAvailableTS={
            authStore.character?.next_daily_reward_available_at ?? 0
          }
          claimedTS={authStore.character?.last_daily_reward_claimed_at ?? 0}
          imgComponent={
            <img
              style={{ height: 48, width: 48, marginLeft: -6 }}
              src="/img/daily-reward-icon.svg"
            />
          }
          onClick={() => {
            store.isShowDailyRewardDialog = true;
          }}
        />
        <RewardButton
          title="Lucky Pick"
          nextAvailableTS={0}
          claimedTS={0}
          text="Pick Now!"
          imgComponent={
            <img
              style={{
                height: 48,
                width: 48,
                marginLeft: -6,
                transform: "translateY(-2px)",
              }}
              src="/img/lucky-pick-icon.svg"
            />
          }
          onClick={() => {
            store.openLuckyPickDialog();
          }}
        />
        <GradiantButton
          style={{
            flex: 1,
            padding: 0,
            overflow: "hidden",
            background: "radial-gradient(#656a6d 0%, #777b7d 100%)",
          }}
          onClick={() => {
            // store.openMagicCratesDialog();
          }}
        >
          <VFlex style={{ gap: 3 }}>
            <Flex style={{ alignItems: "center", gap: 6 }}>
              <img
                style={{
                  height: 48,
                  width: 48,
                  marginLeft: 0,
                  marginRight: -6,
                }}
                src="/img/magic-crate-icon-grey.svg"
              />
              <SBody style={{ fontSize: 12 }}>Magic Crates</SBody>
            </Flex>
            <Flex
              style={{
                backgroundColor: "#204159",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 38,
                width: "100%",
                marginBottom: -13,
                paddingBottom: 13,
              }}
            >
              <S11Body>Coming Soon!</S11Body>
            </Flex>
          </VFlex>
        </GradiantButton>
      </Flex>
      {/* Main Box */}
      <VFlex
        style={{
          flex: 1,
          marginLeft: -2,
          marginRight: -2,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          marginTop: -12,
          // position: "relative",
        }}
        // onTouchStart={handleAddPointClick}
        // onMouseDown={handleAddPointClick}
      >
        <VFlex
          className="relative justify-center items-center px-4 py-4 flex-1 m-4 rounded-md overflow-hidden"
          ref={clickerDivRef as any}
        >
          <img
            style={{
              position: "absolute",
              objectFit: "contain",
              aspectRatio: "1/1",
              width: isClickerDivShort ? "unset" : "100%",
              height: isClickerDivShort ? "100%" : "unset",
            }}
            src="/img/main-bg.png"
          />
          <img
            style={{
              // maxWidth: "100%",
              aspectRatio: "1/1",
              width: isClickerDivShort ? "unset" : "90%",
              height: isClickerDivShort ? "90%" : "unset",
              objectFit: "contain",
              position: "absolute",
              zIndex: 5,
              transform: "translateY(6%)",
            }}
            src="/img/main-character.png"
          />
          <Lottie
            style={{
              position: "absolute",
              pointerEvents: "none",
              zIndex: 4,
              opacity: 0.3,
            }}
            height={store.screenSize.width * 0.8}
            width={store.screenSize.width * 0.8}
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
          />
        </VFlex>
      </VFlex>
      <div style={{ minHeight: 80 }} />
      <Flex
        className="fixed justify-center px-4 h-[92px] mb-[-10px]"
        style={{
          left: 0,
          right: 0,
          bottom: store.bottomTabBarHeight - 30,
        }}
      >
        <STrapezoidBottomBox style={{}}>
          <SCircularGradiantBox
            style={{
              width: "150%",
              zIndex: -1,
            }}
          />
        </STrapezoidBottomBox>
        <STrapezoidBottomBox
          style={{
            left: lrTranslate,
            height: 54,
            transform: "perspective(10px) rotateX(-2deg)",
          }}
        >
          <SCircularGradiantBox
            style={{ bottom: -50, width: "150%", zIndex: -1 }}
          />
        </STrapezoidBottomBox>
        <STrapezoidBottomBox
          style={{
            right: lrTranslate,
            height: 54,
            transform: "perspective(10px) rotateX(-2deg)",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: -1,
              background:
                "linear-gradient(30deg,    #5ea5d6 0%,    #234d6b 40%,    #234d6b 48%,    #5ea5d6 100%  )",
            }}
          />
        </STrapezoidBottomBox>
        <Flex
          style={{
            position: "absolute",
            alignItems: "center",
            transform: "translateY(8px)",
          }}
        >
          <img
            style={{ height: 42, width: 42, marginLeft: -6 }}
            src="/img/orb.png"
          />
          <SBody
            style={{
              font: "normal normal 600 20px/24px Montserrat",
            }}
          >
            {numberInGroups(authStore.character?.energy ?? 0) || "0"}
          </SBody>
        </Flex>
        <VFlex className="absolute left-2">
          <SBody style={{ fontSize: 12 }}>Earn per Hour</SBody>
          <Flex style={{ alignItems: "center", gap: 3, alignSelf: "stretch" }}>
            <img
              style={{ height: 28, width: 28, marginLeft: -3 }}
              src="/img/orb.png"
            />
            <S18Body600
              style={{
                fontSize: earnPerHour.length > 3 ? 12 : 18,
                lineBreak: "anywhere",
                flex: 1,
              }}
            >
              {earnPerHour}
            </S18Body600>
          </Flex>
        </VFlex>
        <VFlex
          className="absolute right-2 simple-btn"
          style={{ gap: 2 }}
          onClick={() => {
            if (!isReflectReady) return;
            store.openReflectDialogState();
          }}
        >
          {isReflectReady && (
            <div
              style={{
                position: "absolute",
                width: 8,
                height: 8,
                borderRadius: 4,
                backgroundColor: "#54DAF3",
                transform: "translate(-14px, 2px)",
              }}
            />
          )}
          <Flex className="gap-2 mt-1">
            <SBody style={{ fontSize: 14 }}>Reflect</SBody>
            <img src="/img/reflect.svg" />
          </Flex>
          <Flex style={{ gap: 6, alignItems: "center" }}>
            <ProgressBar
              style={{ flex: 1, height: 12 }}
              percent={passiveFillPercent * 100}
            />
            <MobxCountdownTimer
              finished={
                <S12BodyLighter style={{ fontSize: 12, fontWeight: "lighter" }}>
                  Ready!
                </S12BodyLighter>
              }
              deadline={passiveReadyMS}
              Wrapper={S12BodyLighter}
            />
          </Flex>
        </VFlex>
      </Flex>
    </VFlex>
  );
});

export default MainTabPage;
