import { makeAutoObservable } from "mobx";
import { getGameConfig } from "../api/api";
import { listLuckyPickPools } from "../api/lucky-pick";
import { listWithdraws } from "../api/withdraw";
import { WIDTH_THRESHOLD } from "../constants";
import {
  DailyRewardResultItem,
  GameConfig,
  HatchEggAPIResBody,
  LuckyPickPools,
  Mission,
  Withdrawal,
} from "../types/api-types";
import { EMagicCrateTypes, ERewardTypes, ETabs } from "../types/types";
import { tryForever } from "../utils";

export type TConfirmDialogState = {
  title: string;
  content: any;
  onResult?: (result: boolean) => void;
  yesText?: string;
  noText?: string;
  hideCancel?: boolean;
  resolve?: (result: boolean) => void;
  reject?: (error: any) => void;
};

export type TPromptDialogState = {
  title: string;
  placeholder?: string;
  content: any;
  onResult?: (result?: string) => void;
  input?: string;
  yesText?: string;
  noText?: string;
  hideCancel?: boolean;
  resolve?: (result?: string) => void;
  reject?: (error: any) => void;
};

class Store {
  screenSize = {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  };

  setScreenSize(s: { width: number; height: number }) {
    this.screenSize = s;
  }

  get isDesktop() {
    return this.screenSize.width > WIDTH_THRESHOLD;
  }

  get bottomTabBarHeight() {
    return this.isOpenPage === "friend" || this.currentTab === ETabs.FRIENDS
      ? 148
      : 84;
  }

  currentTab: ETabs = ETabs.HOME;

  luckyPickPools: LuckyPickPools | null = null;

  clickTextDict: Record<
    string,
    {
      value: number;
      position: {
        left: number;
        top: number;
      };
    }
  > = {};

  isOpenPage: "friend" | "airdrop" | null = null;

  isShowDailyRewardDialog = false;

  luckyPickDialogState:
    | false
    | {
        state:
          | "initial"
          | "choosing"
          | number
          | {
              type: ERewardTypes;
              value: string;
              isJackpot?: boolean;
            };
        poolType?: ERewardTypes;
        showingRewardType: ERewardTypes;
        isShowInfo?: "jackpot" | "how" | "rewards";
      } = false;

  openLuckyPickDialog() {
    store.luckyPickDialogState = {
      showingRewardType: ERewardTypes.TON,
      state: "initial",
    };
  }

  isShowLoadingDialog = false;

  magicCratesDialogState: null | {
    isShowInfo: boolean;
  } = null;

  openMagicCratesDialog() {
    this.magicCratesDialogState = {
      isShowInfo: false,
    };
  }

  buyMagicCratesDialogState: null | {
    unitAmount: string;
    magicCrateType: EMagicCrateTypes;
  } = null;

  openBuyMagicCratesDialog(magicCrateType: EMagicCrateTypes) {
    this.buyMagicCratesDialogState = {
      unitAmount: "0",
      magicCrateType,
    };
  }

  buyMagicCratesResultDialogState: null | {
    unitAmount: number;
    magicCrateType: EMagicCrateTypes;
  } = null;

  magicCratesResultDialogState: null | {
    roundNumber: number;
    maxNumber: number;
  } = null;

  openMagicCratesResultDialog() {
    this.magicCratesResultDialogState = {
      roundNumber: 18,
      maxNumber: 18,
    };
  }

  claimedDialogState: null | {
    title?: string;
    type?: ERewardTypes;
    value?: number;
    rewards?: (
      | {
          type: ERewardTypes;
          amount: number;
        }
      | DailyRewardResultItem
    )[];
  } = null;

  balanceDialogState: null | {
    title?: string;
  } = null;

  missionDialogState: null | {
    mission: Mission;
    verifyFrom?: number;
    verified: boolean;
    isShowAttention: boolean;
    isClaimed: boolean;
  } = null;

  mockFriends: {
    name: string;
    avatar: string;
    value: number;
    claimed?: boolean;
  }[] = [];

  isShowWalletConnectedDialog = false;

  withdrawDialogState: {
    amount: string;
    type: ERewardTypes.TON | ERewardTypes.WELL;
  } | null = null;

  openWithdrawDialog(type: ERewardTypes.TON | ERewardTypes.WELL) {
    this.withdrawDialogState = {
      amount: "",
      type,
    };
  }

  gameConfig: GameConfig | null = null;

  hatchedDialogState: HatchEggAPIResBody | null = null;

  reflectDialogState: {
    currentState: "initial" | "reflecting" | "done";
    startingTime: number;
    increasedEnergy: number;
  } | null = null;

  openReflectDialogState() {
    this.reflectDialogState = {
      currentState: "initial",
      startingTime: 0,
      increasedEnergy: 0,
    };
  }

  async fetchGameConfig() {
    await tryForever(async () => {
      const res = await getGameConfig();
      this.gameConfig = res.body.game_config;
      const res2 = await listLuckyPickPools();
      this.luckyPickPools = res2.body;
    }, "fetchGameConfig");
  }

  isShowWithdrawalRequestedDialog: null | ERewardTypes = null;

  isShowWithdrawalHistoryDialog: null | { withdrawals: Withdrawal[] | null } =
    null;

  openWithdrawalHistoryDialog() {
    (async () => {
      const res = await listWithdraws();
      if (this.isShowWithdrawalHistoryDialog) {
        this.isShowWithdrawalHistoryDialog.withdrawals = res.body.withdrawals;
      }
    })();
    this.isShowWithdrawalHistoryDialog = {
      withdrawals: null,
    };
  }

  confirmDialogState: null | TConfirmDialogState = null;

  confirm = (confirmDialogState: TConfirmDialogState) => {
    return new Promise((resolve, reject) => {
      this.confirmDialogState = {
        ...confirmDialogState,
        resolve,
        reject,
      };
    });
  };

  promptDialogState: null | TPromptDialogState = null;

  prompt = (promptDialogState: TPromptDialogState) => {
    return new Promise<string>((resolve, reject) => {
      this.promptDialogState = {
        ...promptDialogState,
        resolve: resolve as any,
        reject,
      };
    });
  };

  showErrorDialog(error: any, onPrimaryButtonClick?: () => void) {
    if (!error) return;
    let message = error?.response?.data?.message || error.message;
    if (message.includes("Not enough pool balance. Please contact Keungz")) {
      message = "Not enough pool balance. Please try again after 24 hours.";
    }
    const match = message.match(/"message":"([^"]+)"/i);
    if (match) {
      message = match[1];
    }
    let baseErrorObj: TConfirmDialogState = {
      title: "",
      content: "",
    };
    if (error.data) {
      baseErrorObj.title = `Error`;
      baseErrorObj.content = `${message} | ${error.data.message} (code: ${error.data.code})`;
    } else if (error.error_code) {
      baseErrorObj.title = `Error`;
      baseErrorObj.content = message;
    } else if (
      error.code === 4001 ||
      error.message.includes("User rejected the request.")
    ) {
      return;
    } else if (error.code) {
      baseErrorObj.title = `Error`;
      baseErrorObj.content = message;
    } else {
      baseErrorObj.title = "Error";
      baseErrorObj.content = `${error}`;
    }
    console.log(baseErrorObj);
    this.confirmDialogState = baseErrorObj;
  }

  isShowTxInProgressDialog = false;

  constructor() {
    makeAutoObservable(this);
  }
}

export const store = new Store();
