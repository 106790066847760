interface APIResponse<T> {
  status: number;
  body: T;
}

export type APIInput = {
  uri: string;
  method: string;
  headers?: any;
  body?: any;
  isForm?: boolean;
};

export type APIResAsync<T> = Promise<APIResponse<T>>;

export enum ETabs {
  HOME,
  SKILLS,
  MISSIONS,
  LAUNCH,
  MORE,
  FRIENDS,
  AIRDROP,
}

export interface ISize {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}

export enum ERewardTypes {
  TON = "ton",
  WELL = "well",
  Energy = "energy",
}

export enum EMagicCrateTypes {
  Basic,
  Rare,
  Mythic,
}
