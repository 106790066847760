import { SVGProps } from ".";
const SkillSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...p}
    >
      <g transform="translate(0 8)">
        <rect width="32" height="32" transform="translate(0 -8)" fill="none" />
        <g transform="translate(2.414 -5.642)">
          <path
            d="M24.236,10.887,15.783,2.7a3.172,3.172,0,0,0-4.382,0L2.936,10.89c-.917.886-.815,1.61-.672,1.963s.571.944,1.848.944H7.16v8.592a3.079,3.079,0,0,0,3.075,3.075h6.713a3.079,3.079,0,0,0,3.075-3.075V13.8h3.036c1.275,0,1.7-.592,1.848-.945s.245-1.078-.671-1.964Zm-1.176,1.4H19.368a.85.85,0,0,0-.85.847v9.251a1.571,1.571,0,0,1-1.57,1.57H10.234a1.572,1.572,0,0,1-1.57-1.57V13.114a.882.882,0,0,0-.827-.823H4.109a1.727,1.727,0,0,1-.373-.035,1.649,1.649,0,0,1,.244-.285l8.467-8.194a1.655,1.655,0,0,1,2.287,0l8.455,8.191a1.739,1.739,0,0,1,.244.286,1.632,1.632,0,0,1-.374.037Z"
            transform="translate(0 0)"
            fill={fill || "#fff"}
          />
          <path
            d="M13,12.742a.753.753,0,0,0-1.064,0l-1.911,1.911a.753.753,0,0,0,1.065,1.065l1.378-1.38,1.475,1.475a.753.753,0,0,0,1.064-1.066Z"
            transform="translate(1.232 1.726)"
            fill={fill || "#fff"}
          />
          <path
            d="M13,16.12a.752.752,0,0,0-1.064,0l-1.911,1.911A.753.753,0,1,0,11.091,19.1l1.378-1.38,1.475,1.475a.752.752,0,0,0,1.064-1.064L13,16.121Z"
            transform="translate(1.232 2.271)"
            fill={fill || "#fff"}
          />
        </g>
      </g>
    </svg>
  );
};

export default SkillSVG;
