import { useRef } from "react";
import { SVGProps } from ".";
const DialogCloseBtnSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  const idRef = useRef(`${Math.round(Math.random() * 1000)}`);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="64"
      height="42.667"
      viewBox="0 0 64 42.667"
      {...p}
    >
      <defs>
        <linearGradient
          id={`linear-gradient-${idRef.current}`}
          x1="0.584"
          y1="1.186"
          x2="0.577"
          y2="0.237"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#272e33" />
          <stop offset="1" stop-color="#375974" />
        </linearGradient>
      </defs>
      <g transform="translate(-310 -118)">
        <g
          transform="translate(310 118)"
          fill={`url(#linear-gradient-${idRef.current})`}
        >
          <path
            d="M 56 42.16666030883789 L 8 42.16666030883789 C 3.896293878555298 42.16666030883789 0.5517927408218384 38.61795043945312 0.5005941390991211 34.23519134521484 L 11.49326705932617 8.752931594848633 C 13.10099601745605 5.820887088775635 15.21860218048096 3.609698295593262 17.78742027282715 2.180850028991699 C 20.41682052612305 0.7183100581169128 22.78342056274414 0.4999300539493561 23.9601001739502 0.4999300539493561 L 23.99810028076172 0.500010073184967 L 56 0.500010073184967 C 60.1355094909668 0.500010073184967 63.5 4.103750228881836 63.5 8.533340454101562 L 63.5 34.13333129882812 C 63.5 38.56291961669922 60.1355094909668 42.16666030883789 56 42.16666030883789 Z"
            stroke="none"
          />
          <path
            d="M 23.95951080322266 0.9999198913574219 C 22.8313102722168 0.9999198913574219 20.560791015625 1.210109710693359 18.03020095825195 2.617778778076172 C 15.55111694335938 3.996807098388672 13.50335311889648 6.134647369384766 11.9429931640625 8.972732543945312 L 1.002452850341797 34.33414840698242 C 1.101764678955078 38.39553451538086 4.202621459960938 41.66666030883789 8 41.66666030883789 L 56 41.66666030883789 C 59.85980987548828 41.66666030883789 63 38.2872200012207 63 34.13333129882812 L 63 8.533340454101562 C 63 4.379440307617188 59.85980987548828 1 56 1 L 23.95951080322266 0.9999198913574219 M 23.96013259887695 -7.62939453125e-05 C 23.97323608398438 -7.62939453125e-05 23.98695373535156 -4.9591064453125e-05 24 0 L 56 0 C 60.41828155517578 0 64 3.820510864257812 64 8.533340454101562 L 64 34.13333129882812 C 64 38.84614944458008 60.41828155517578 42.66666030883789 56 42.66666030883789 L 8 42.66666030883789 C 3.581718444824219 42.66666030883789 0 38.84614944458008 0 34.13333129882812 L 11.04346084594727 8.533340454101562 C 14.72280502319336 1.805618286132812 20.39463424682617 -0.0002899169921875 23.96013259887695 -7.62939453125e-05 Z"
            stroke="none"
            fill="#14212a"
          />
        </g>
        <path
          d="M18.43,16.435l5.587-5.587a1.413,1.413,0,0,0-2-2l-5.587,5.587L10.848,8.849a1.414,1.414,0,0,0-2,2l5.587,5.587L8.849,22.021a1.414,1.414,0,0,0,2,2l5.587-5.587,5.587,5.587a1.414,1.414,0,1,0,2-2Z"
          transform="translate(331.565 122.565)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default DialogCloseBtnSVG;
