import { useRef } from "react";
import { SVGProps } from ".";
const WellSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  const idRef = useRef(`${Math.round(Math.random() * 1000)}`);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="60.489"
      height="29.312"
      viewBox="0 0 60.489 29.312"
      {...p}
    >
      <defs>
        <clipPath id={`clip-path-${idRef}`}>
          <rect width="60.489" height="29.312" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_433" data-name="Group 433" transform="translate(0 0)">
        <g transform="translate(0 0)" clip-path={`url(#clip-path-${idRef})`}>
          <path
            d="M28.59,21.623,21.623,28.59a2.464,2.464,0,0,1-3.484,0L.721,11.172a2.463,2.463,0,0,1,0-3.483L7.688.721a2.464,2.464,0,0,1,3.484,0L28.59,18.139a2.464,2.464,0,0,1,0,3.484"
            transform="translate(0 0)"
            fill={fill || "#fff"}
          />
          <path
            d="M21.641,19.881,30.35,28.59a2.464,2.464,0,0,0,3.484,0L51.252,11.172a2.464,2.464,0,0,0,0-3.484L44.285.721a2.463,2.463,0,0,0-3.483,0Z"
            transform="translate(-12.238 0)"
            fill={fill || "#fff"}
          />
          <path
            d="M100.736,7.689,93.768.722a2.463,2.463,0,0,0-3.484,0l-7.39,7.39L84.124,9.34l7.39-7.39a.725.725,0,0,1,1.025,0l6.968,6.967a.725.725,0,0,1,0,1.026L82.089,27.362a.725.725,0,0,1-1.026,0l-7.387-7.387L72.447,21.2l7.387,7.387a2.464,2.464,0,0,0,3.483,0l17.418-17.418a2.464,2.464,0,0,0,0-3.484"
            transform="translate(-40.969 0)"
            fill={fill || "#fff"}
          />
        </g>
      </g>
    </svg>
  );
};

export default WellSVG;
