import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import styled from "styled-components";
import { claimReferrerReward } from "../../api/referral";
import { authStore } from "../../store/auth-store";
import { store } from "../../store/store";
import { ERewardTypes } from "../../types/types";
import { numberInGroups } from "../../utils";
import Header from "../Header";
import { DiagonalGradiantButton } from "../MainButton";
import { Flex, VFlex } from "../styled/Flex";
import FriendsSVG from "../SVG/FriendsSVG";
import RegularCircleCheckSVG from "../SVG/RegularCircleCheckSVG";
import {
  S11Body,
  S12Body,
  S14Body600,
  S18BodyBold,
  SBody,
  SBody600,
  SBodyBold,
} from "../Typography";
interface FriendsTabPageProps {
  style?: CSSProperties;
}

const SFriendListItemContainer = styled(Flex)`
  align-self: stretch;
  height: 53px;
  background: #ffffff10;
  border: 1px solid #82b3d6;
  border-radius: 9px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  align-items: center;
  padding: 0px 11px 0px 9px;
`;

const FriendsTabPage = observer((props: FriendsTabPageProps) => {
  return (
    <VFlex
      style={{
        flex: 1,
        alignItems: "stretch",
        overflow: "hidden",
      }}
    >
      <Header />
      <Flex
        className="justify-between items-center mt-3 px-4 py-2"
        style={{
          borderBottom: "1px solid #81B3D6",
        }}
      >
        <Flex className="items-center gap-1">
          <FriendsSVG fill="#90bedf" />
          <S18BodyBold>Friends</S18BodyBold>
        </Flex>
        <VFlex className="items-end">
          <S12Body>Your referral code</S12Body>
          <SBody600>{authStore.character?.referral_code}</SBody600>
        </VFlex>
      </Flex>
      <VFlex style={{ overflowY: "scroll" }}>
        <VFlex
          style={{
            margin: "8px 12px",
          }}
        >
          <S14Body600
            style={{
              alignSelf: "center",
            }}
          >
            Invite your friends
          </S14Body600>
          <SBody className="text-center">
            You and your friends will receive bonuses!
          </SBody>
          <Flex className="gap-2 mt-2 mb-1">
            <Flex
              style={{
                border: "1px solid #82B3D6",
                borderRadius: 8,
                flex: 1,
                height: 56,
                background: "#3d4f5d",
                alignItems: "center",
              }}
            >
              <img src="/img/orb.png" className="w-9 h-9" />
              <VFlex>
                <S11Body>Rewards for you:</S11Body>
                <SBody600 className="text-[#54DAF3]">{`+${numberInGroups(store.gameConfig?.referral_config.referrer_reward.amount ?? 0)}`}</SBody600>
              </VFlex>
            </Flex>
            <Flex
              style={{
                border: "1px solid #82B3D6",
                borderRadius: 8,
                flex: 1,
                height: 56,
                background: "#3d4f5d",
                alignItems: "center",
              }}
            >
              <img src="/img/orb.png" className="w-9 h-9" />
              <VFlex>
                <S11Body>Rewards for friends:</S11Body>
                <SBody600 className="text-[#54DAF3]">{`+${numberInGroups(store.gameConfig?.referral_config.referee_reward.amount ?? 0)}`}</SBody600>
              </VFlex>
            </Flex>
          </Flex>
        </VFlex>
        <Flex
          style={{
            position: "sticky",
            top: 0,
            zIndex: 2,
          }}
          className="bg-black min-h-[57px] items-center px-4 justify-between"
        >
          <SBodyBold>Friends list</SBodyBold>
          <DiagonalGradiantButton
            style={{
              width: 172,
            }}
            disabled={
              !authStore.character?.referree ||
              authStore.character.referree.every(
                (friend) => friend.reward_claimed,
              ) ||
              authStore.character.referree.length === 0
            }
            onClick={async () => {
              if (store.isShowLoadingDialog) return;
              store.isShowLoadingDialog = true;
              try {
                const r = await claimReferrerReward();
                authStore.character = r.body.character;
                store.claimedDialogState = {
                  type: ERewardTypes.Energy,
                  value: r.body.rewards.reduce((a, b) => a + b.amount, 0),
                };
              } catch (error) {
                store.showErrorDialog(error);
              }
              store.isShowLoadingDialog = false;
            }}
          >
            Claim All
          </DiagonalGradiantButton>
        </Flex>
        <VFlex className="items-center flex-1 pt-[14px] pb-[24px]">
          {authStore.character?.referred_by && (
            <SFriendListItemContainer
              key={authStore.character?.referred_by.tg_user_id}
              className="mx-4 mb-2"
            >
              {/* <img
                      src={""}
                      style={{
                        width: 28,
                        height: 28,
                        borderRadius: 100,
                        border: "1px solid #54DAF3",
                        background: "#163A54",
                      }}
                    /> */}
              <S14Body600 className="ml-2 flex-1">
                {authStore.character?.referred_by.name ||
                  authStore.character.referred_by.tg_user_id}
              </S14Body600>
              <Flex className="items-center gap-1">
                <S14Body600>Claimed</S14Body600>
                <RegularCircleCheckSVG />
              </Flex>
            </SFriendListItemContainer>
          )}
          {(authStore.character?.referree?.length ?? 0) > 0 && (
            <VFlex className="self-stretch flex-1 gap-2 px-4">
              {authStore.character?.referree?.map((friend, index) => {
                return (
                  <SFriendListItemContainer key={friend.tg_user_id}>
                    {/* <img
                      src={""}
                      style={{
                        width: 28,
                        height: 28,
                        borderRadius: 100,
                        border: "1px solid #54DAF3",
                        background: "#163A54",
                      }}
                    /> */}
                    <S14Body600 className="ml-2 flex-1">
                      {friend.name || friend.tg_user_id}
                    </S14Body600>
                    {friend.reward_claimed ? (
                      <Flex className="items-center gap-1">
                        <S14Body600>Claimed</S14Body600>
                        <RegularCircleCheckSVG />
                      </Flex>
                    ) : (
                      <>
                        <img className="w-7 h-7" src="/img/orb.png" />
                        <S14Body600>{`+${numberInGroups(friend.reward.amount)}`}</S14Body600>
                      </>
                    )}
                  </SFriendListItemContainer>
                );
              })}
            </VFlex>
          )}
          {!(authStore.character?.referree?.length ?? 0) &&
            !authStore.character?.referred_by && (
              <>
                <FriendsSVG style={{ width: 46, height: 46 }} fill="#90bedf" />
                <S14Body600 className="text-center mt-2">
                  No friends in your list yet,
                </S14Body600>
                <S14Body600 className="text-center">
                  invite your friends and earn a bonus!
                </S14Body600>
              </>
            )}
        </VFlex>
      </VFlex>
    </VFlex>
  );
});

export default FriendsTabPage;
