import { WithdrawalsResponse } from "../types/api-types";
import { ERewardTypes } from "../types/types";
import { get, post } from "./api";

export const withdraw = async (
  amount: number,
  currency: ERewardTypes,
  dest: string,
) => {
  return await post<{
    amount: string;
    nonce: number;
    nonce_idx: number;
    payload: string;
    query_id: number;
    signature: string;
    timestamp: number;
    token: number;
    wallet: string;
  }>({
    uri: "/character/withdraws",
    body: {
      amount,
      currency,
      dest,
    },
  });
};

export const listWithdraws = async () => {
  return await get<WithdrawalsResponse>({
    uri: "/character/withdraws?skip=0&limit=10",
  });
};
