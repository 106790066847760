import { observer } from "mobx-react-lite";
import { CSSProperties, useEffect, useState } from "react";
import { store } from "../store/store";
import { sleep } from "../utils";
import { SBody } from "./Typography";
interface ClickPopupTextProps {
  style?: CSSProperties;
  value: number;
  position: {
    left: number;
    top: number;
  };
  k: any;
}

const ClickPopupText = observer((props: ClickPopupTextProps) => {
  const { k, position, value } = props;
  const [{ left, top, scale }, setPosition] = useState({
    ...position,
    scale: 1,
  });
  useEffect(() => {
    requestAnimationFrame(async () => {
      setPosition({
        top: position.top - 5,
        left: position.left,
        scale: 1,
      });
      await sleep(300);
      setPosition({
        top: position.top - 5,
        left: position.left,
        scale: 0,
      });
      await sleep(300);
      delete store.clickTextDict[k];
    });
  }, []);
  if (!k) return null;
  return (
    <SBody
      style={{
        position: "fixed",
        top,
        left,
        transform: `scale(${scale})`,
        zIndex: 10,
        transition: "all 0.3s",
        pointerEvents: "none",
      }}
    >{`+${value}`}</SBody>
  );
});

export default ClickPopupText;
