import { observer } from "mobx-react-lite";
import { now } from "mobx-utils";
import { CSSProperties, useState } from "react";
import { levelUpItem } from "../../api/character";
import { hatchAnEgg, hatchAnEggImmediately } from "../../api/egg";
import { EGG_INFO, REWARD_DATA, TAB_BTN_STYLES } from "../../constants";
import { authStore } from "../../store/auth-store";
import { store } from "../../store/store";
import { Egg, Rarity } from "../../types/api-types";
import { getTimeLeftSimpleStr, numberInGroups } from "../../utils";
import Header from "../Header";
import { MainButton, MainButtonV2 } from "../MainButton";
import ProgressBar from "../ProgressBar";
import { Flex, VFlex } from "../styled/Flex";
import ChevaronRightSVG from "../SVG/ChevaronRightSVG";
import SkillSVG from "../SVG/SkillSVG";
import TimerSVG from "../SVG/TimerSVG";
import TopRightTag from "../TopRightTag";
import {
  S11Body,
  S14Body,
  S14Body600,
  S14BodyBold,
  S18BodyBold,
  S20Body600,
  SBody600,
  SBodyBold,
} from "../Typography";
interface SkillsTabPageProps {
  style?: CSSProperties;
}

const SkillsTabPage = observer((props: SkillsTabPageProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const items = authStore.character?.items || [];
  const n = now() / 1000;
  const lvlEgg = authStore.character?.eggs[0];
  return (
    <VFlex
      style={{
        flex: 1,
        alignItems: "stretch",
        overflow: "hidden",
      }}
    >
      <Header />
      <VFlex
        style={{
          padding: "20px 16px 14px 16px",
          background: "linear-gradient(0deg,#122532 0%, #15354c 100%)",
          borderBottom: "1px solid #81B3D6",
        }}
      >
        <Flex
          style={{
            alignItems: "center",
          }}
        >
          <SkillSVG style={{ width: 44, height: 44 }} fill="#88b7d9" />
          <S18BodyBold style={{ marginLeft: 6 }}>Skills</S18BodyBold>
          <div style={{ flex: 1 }} />
          <img style={{ width: 46, height: 46 }} src="/img/orb.png" />
          <S20Body600 style={{ marginLeft: 6 }}>
            {numberInGroups(authStore.character?.energy ?? 0)}
          </S20Body600>
        </Flex>
      </VFlex>
      <VFlex className="flex-1 overflow-y-scroll">
        <VFlex
          style={{
            borderBottom: "1px solid #81B3D6",
          }}
        >
          <VFlex className="px-4 py-2 gap-2">
            <SBody600>Level up reward</SBody600>
            <Flex
              style={{
                position: "relative",
                height: 86,
                alignSelf: "stretch",
                alignItems: "center",
                padding: "0px 8px",
                gap: 8,
              }}
              className="diagonal-container"
            >
              <VFlex
                style={{
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <Flex className="items-center gap-1">
                  <S14Body>{`LVL ${authStore.expData?.level}`}</S14Body>
                  <ChevaronRightSVG fill="#54DAF3" />
                  <S14BodyBold
                    style={{ color: "#54DAF3" }}
                  >{`LVL ${(authStore.expData?.level ?? 0) + 1}`}</S14BodyBold>
                </Flex>
                <ProgressBar
                  style={{
                    width: 140,
                  }}
                  percent={
                    (authStore.currentExp / authStore.nextExpRequired) * 100
                  }
                />
                <Flex>
                  <S14Body>
                    {numberInGroups(authStore.currentExp)} /&nbsp;
                  </S14Body>
                  <S14Body style={{ color: "#54DAF3" }}>
                    {authStore.nextExpRequired
                      ? numberInGroups(authStore.nextExpRequired)
                      : "--"}
                  </S14Body>
                </Flex>
              </VFlex>
              <VFlex
                style={{
                  border: "1px solid #54DAF3",
                  borderRadius: 8,
                }}
              >
                <img
                  style={{ height: 68 }}
                  src={EGG_INFO[lvlEgg?.rarity ?? Rarity.BASIC].img}
                />
              </VFlex>
              <TopRightTag
                style={{ width: 70 }}
                background={"#405A6D"}
                text={`x${authStore.character?.eggs.length}`}
              />
              <div className="flex-1" />
              <MainButton
                style={{
                  alignSelf: "flex-end",
                  transform: "translateY(-6px)",
                }}
                disabled={!authStore.character?.eggs.length}
                onClick={async () => {
                  if (store.isShowLoadingDialog || !lvlEgg) return;
                  store.isShowLoadingDialog = true;
                  try {
                    const res = await hatchAnEgg(lvlEgg.id);
                    authStore.character = res.body.character;
                    store.hatchedDialogState = res.body;
                    store.isShowLoadingDialog = false;
                  } catch (error) {
                    store.showErrorDialog(error);
                    store.isShowLoadingDialog = false;
                  }
                }}
              >
                Open
              </MainButton>
            </Flex>
          </VFlex>
          <Flex className="gap-2 px-4 pb-4">
            {Object.entries(EGG_INFO).map(([k, { title, img }], idx) => {
              const egg = authStore.character?.standard_eggs[idx] as Egg;
              const eggData = store.gameConfig?.egg_table[egg?.rarity];
              const timeLeft = (egg?.unlock_at ?? 0) - n;
              const timeLeftStr =
                timeLeft > 0
                  ? getTimeLeftSimpleStr(timeLeft * 1000)
                  : "00:00:00";
              return (
                <VFlex className="diagonal-container flex-1 items-center px-[6px] gap-2 p-2">
                  <S14Body600>{title}</S14Body600>
                  <img
                    style={{
                      border: "1px solid #54DAF3",
                      borderRadius: 8,
                    }}
                    src={img}
                  />
                  <Flex className="gap-1">
                    <TimerSVG />
                    <S14Body600 style={{}}>{timeLeftStr}</S14Body600>
                  </Flex>
                  <MainButton
                    style={{
                      height: 32,
                      alignSelf: "stretch",
                      borderRadius: 4,
                      padding: 0,
                    }}
                    disabled={
                      timeLeft > 0 &&
                      (authStore.character?.energy ?? 0) <
                        (eggData?.reset_energy ?? 0)
                    }
                    onClick={async () => {
                      if (store.isShowLoadingDialog) return;
                      if (timeLeft > 0) {
                        const result = await store.confirm({
                          title: "Hatch Immediately",
                          content: (
                            <>
                              <img src={img} />
                              <Flex className="mt-4">
                                <img
                                  className="w-8 h-8"
                                  src={REWARD_DATA.energy.icon}
                                />
                                <SBody600>
                                  {numberInGroups(eggData?.reset_energy ?? 0)}
                                </SBody600>
                              </Flex>
                            </>
                          ),
                        });
                        if (!result) return;
                      }
                      store.isShowLoadingDialog = true;
                      try {
                        const res = await (
                          timeLeft < 0 ? hatchAnEgg : hatchAnEggImmediately
                        )(egg.id);
                        authStore.character = res.body.character;
                        store.hatchedDialogState = res.body;
                        store.isShowLoadingDialog = false;
                      } catch (error) {
                        store.showErrorDialog(error);
                        store.isShowLoadingDialog = false;
                      }
                    }}
                  >
                    {timeLeft < 0 ? (
                      "Open"
                    ) : (
                      <Flex className="items-center">
                        <img
                          style={{ width: 24, height: 24 }}
                          src="/img/orb.png"
                        />
                        <VFlex className="items-start">
                          <S11Body style={{ fontWeight: 500 }}>
                            Open Now
                          </S11Body>
                          <S14BodyBold style={{ marginTop: -12 }}>
                            {numberInGroups(eggData?.reset_energy ?? 0)}
                          </S14BodyBold>
                        </VFlex>
                      </Flex>
                    )}
                  </MainButton>
                </VFlex>
              );
            })}
          </Flex>
        </VFlex>
        <Flex
          style={{
            borderBottom: "1px solid #81B3D6",
            padding: "10px 16px",
            gap: 8,
            overflowX: "scroll",
            overflowY: "hidden",
            minHeight: 62,
            position: "sticky",
            top: 0,
            zIndex: 10,
            backgroundColor: "#071928",
          }}
        >
          {[
            { id: 888, category_name: "All" },
            ...(store.gameConfig?.item_categories ?? []),
          ].map((w, idx) => {
            return (
              <MainButtonV2
                key={w.id}
                style={{
                  flex: 1,
                  color: "white",
                  transition: "all 0.3s ease",
                  ...(currentTab === idx
                    ? TAB_BTN_STYLES.active
                    : TAB_BTN_STYLES.inactive),
                }}
                onClick={() => {
                  setCurrentTab(idx);
                }}
              >
                <SBody600>{w.category_name}</SBody600>
              </MainButtonV2>
            );
          })}
        </Flex>
        {items.length === 0 ? (
          <SBodyBold className="text-center self-center mx-3 my-8">
            You don't have any item yet, go collect some!
          </SBodyBold>
        ) : (
          <div className="grid grid-cols-2 gap-2 p-4 pb-8">
            {items.map((i) => {
              const itemStat = store.gameConfig?.item_table[i.rarity];
              if (currentTab !== 0 && `${currentTab - 1}` !== i.category)
                return null;
              return (
                <VFlex key={i.id} className="w-full items-stretch">
                  <Flex className="diagonal-container items-center p-2">
                    <div
                      style={{
                        border: "1px solid #707070",
                        backgroundColor: "#111111",
                        height: 84,
                        width: 84,
                        borderRadius: 8,
                        overflow: "auto",
                      }}
                    >
                      <img
                        src={`/img/skills/${i.item_id}.jpg?123`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <VFlex className="items-center flex-1">
                      <TopRightTag
                        style={{
                          width: 60,
                          position: "unset",
                          marginTop: -18,
                          marginRight: -8,
                          alignSelf: "flex-end",
                        }}
                        background="#81B3D6"
                        text={`LVL ${i.level}`}
                      />
                      <img src="/img/orb.png" className="w-[34px] h-[34px]" />
                      <S14Body600>{`+${itemStat?.increase_exp_per_hour[i.level]}/h`}</S14Body600>
                    </VFlex>
                  </Flex>
                  <MainButtonV2
                    style={{
                      justifyContent: "space-between",
                      height: 32,
                      marginTop: 8,
                      borderRadius: 4,
                    }}
                    onClick={async () => {
                      if (store.isShowLoadingDialog) return;
                      store.isShowLoadingDialog = true;
                      try {
                        const character = await levelUpItem(i.id);
                        authStore.character = character;
                        store.isShowLoadingDialog = false;
                      } catch (error) {
                        store.showErrorDialog(error);
                        store.isShowLoadingDialog = false;
                      }
                    }}
                    disabled={
                      (authStore.character?.energy ?? 0) <
                      parseInt(itemStat?.level_up_energy[i.level] || "0", 10)
                    }
                  >
                    <S14BodyBold>Upgrade</S14BodyBold>
                    <Flex className="items-center gap-1">
                      <img
                        src="/img/orb.png"
                        style={{ width: 26, height: 26 }}
                      />
                      <S14BodyBold>
                        {itemStat?.level_up_energy[i.level]}
                      </S14BodyBold>
                    </Flex>
                  </MainButtonV2>
                </VFlex>
              );
            })}
          </div>
        )}
      </VFlex>
    </VFlex>
  );
});

export default SkillsTabPage;
