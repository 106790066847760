import { Character } from "../types/api-types";
import { ERewardTypes } from "../types/types";
import { post } from "./api";

export const claimReferrerReward = async () => {
  return post<{
    character: Character;
    rewards: {
      amount: number;
      type: ERewardTypes;
    }[];
  }>({
    uri: "/referral/claim_referrer_reward",
  });
};

export const setReferrer = async (referralCode: string) => {
  return post<{
    character: Character;
    reward: {
      amount: number;
      type: ERewardTypes;
    };
  }>({
    uri: "/referral/set_referrer",
    body: {
      referral_code: referralCode,
    },
  });
};
