import { SVGProps } from ".";
const HeartSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.402"
      height="18.302"
      viewBox="0 0 21.402 18.302"
      {...p}
    >
      <path
        d="M1.99,13.759,9.543,20.81a1.7,1.7,0,0,0,2.316,0l7.553-7.052A6.253,6.253,0,0,0,21.4,9.181V8.939A5.975,5.975,0,0,0,11.2,4.713l-.5.5-.5-.5A5.975,5.975,0,0,0,0,8.939v.242A6.253,6.253,0,0,0,1.99,13.759Z"
        transform="translate(0 -2.964)"
        fill={fill || "#fff"}
      />
    </svg>
  );
};

export default HeartSVG;
