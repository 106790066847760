import { observer } from "mobx-react";
import { CSSProperties } from "react";
import { VFlex } from "./styled/Flex";
import { S12BodyBold } from "./Typography";
interface TopRightTagProps {
  style?: CSSProperties;
  background: string;
  text: string;
}

const TopRightTag = observer((props: TopRightTagProps) => {
  const { background, style, text } = props;
  return (
    <VFlex
      className="absolute top-0 right-0 items-center justify-center h-7 w-[94px]"
      style={{
        background,
        borderRadius: "0px 8px",
        ...style,
      }}
    >
      <S12BodyBold>{text}</S12BodyBold>
    </VFlex>
  );
});

export default TopRightTag;
