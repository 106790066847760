import { useInitData } from "@vkruglikov/react-telegram-web-app";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { authStore } from "../../store/auth-store";

const AuthLoader = observer(() => {
  const [initDataUnsafe, initData] = useInitData();
  useEffect(() => {
    const signInAsync = async () => {
      if (initData) {
        await authStore.signIn(initData);
        if (authStore.character?.name === "O WO") {
          (function () {
            var script = document.createElement("script");
            script.src = "//cdn.jsdelivr.net/npm/eruda";
            document.body.appendChild(script);
            script.onload = function () {
              (window as any).eruda.init();
            };
          })();
        }
      } else {
        await authStore.signInDev("1a");
      }
    };
    signInAsync();
  }, []);

  return <></>;
});

export default AuthLoader;
